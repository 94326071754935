import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import qs from "qs";
import { QueryHelper } from "services/QueryHelper";
import { Player } from "models/Player";
import "./search-results.css";
import { EMPTY_QUERY, PlayerQuery } from "models/Query";
import ResultItem from "./components/result-item";
import ResultHeader from "./components/result-header";
import { Button, Spinner } from "react-bootstrap";
import AdvancedSearch from "./advanced-search";
import SaveSearchModal from "../../modals/save-search-modal";
import UnderlineButton from "shared/underline-button";
import { compose } from "recompose";
import { withFirestore } from "react-redux-firebase";
import { AiOutlineSearch } from "react-icons/ai";
import colors from "constants/colors";
import HelpModal from "modals/help-modal";
import ScoutTitle from "shared/scout-title";
import { useFlagMode } from "shared/useFlagMode";
import * as XLSX from "xlsx";

function SearchResults(props: any) {
  let location = useLocation();
  let history = useHistory();
  const flagMode = useFlagMode();
  const [results, setResults] = useState<Player[]>([]);
  const [secondaryCount, setSecondaryCount] = useState(0);
  const [count, setCount] = useState(0);
  const [showAdvanced, setShowAdvanced] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [currentSortType, setCurrentSortType] = useState(null);
  const [currentSortDirection, setCurrentSortDirection] = useState(null);
  const [url, setUrl] = useState("");
  const [query, setQuery] = useState(EMPTY_QUERY);
  const [loading, setLoading] = useState(false);
  const [fullAdvancedSearch, setFullAdvancedSearch] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [lastKey, setLastKey] = useState("");

  useEffect(() => {
    fetchDataForURLParams();
  });

  useEffect(() => {
    history.listen((location, action) => {
      console.log("history setHasSearched false");
      setHasSearched(false);
    });
  }, []);

  const SORT_MAP = {
    Player: "last_name.keyword",
    Height: "calculated_height",
    Weight: "weight",
    Age: "age",
    Position: "primary_position.keyword",
    Class: "graduating_class",
    Team: "summer_team.keyword",
    Commitment: "college_commitment.keyword",
    Location: "state.keyword",
    "National Rank": "national_overall_ranking",
    "State Rank": "state_overall_ranking",
  };

  const COLLEGE_SORT_MAP = {
    Player: "last_name.keyword",
    Height: "calculated_height",
    Weight: "weight",
    Position: "position.keyword",
    Class: "classKey",
    Commitment: "university.keyword",
  };

  const reset = () => {
    setResults([]);
    setCount(0);
    setSecondaryCount(0);
    setLoading(false);
  };

  const handleSort = async (key, direction) => {
    if (key && direction) {
      let sortby = query.asCollege ? COLLEGE_SORT_MAP[key] : SORT_MAP[key];

      let res = await QueryHelper.loadedSortedForUrl(
        url,
        sortby,
        direction === "asc",
        false
      );

      console.log("res", res);

      if (
        sortby == "college_commitment.keyword" &&
        res &&
        res.data &&
        res.count == res.data.length
      ) {
        console.log("get res two");
        let resTwo = await QueryHelper.loadedSortedForUrl(
          url,
          sortby,
          direction === "asc",
          true
        );

        let finalResults =
          resTwo && resTwo.data ? [...res.data, ...resTwo.data] : res.data;
        let finalCount = resTwo ? res.count + resTwo.count : res.count;
        finish(finalResults, res.count, resTwo.count);
      } else if (res && res.data) {
        finish(res.data, res.count, 0);
      }
    } else {
      getResults(url);
    }
  };

  const finish = (finalResults, resultCount, secondaryCount) => {
    setLoading(true);
    setTimeout((_) => {
      setResults([]);
      setLoading(false);
      setResults(finalResults);
      setCount(resultCount);
      setSecondaryCount(secondaryCount);
    }, 100);
  };

  let shouldSearch = false;

  const addRangeToQuery = (query: any, params: any, paramName: string) => {
    if (params[paramName] && params[paramName].split(",").length > 0) {
      shouldSearch = true;
      let min = params[paramName].split(",")[0];
      let max = params[paramName].split(",")[1];
      return {
        ...query,
        [paramName]: {
          min: min,
          // max: min == max ? parseFloat(min) + 1 : max, tara here
          max: max,
        },
      };
    }
    return query;
  };

  const fetchDataForURLParams = async () => {
    if (!hasSearched) {
      console.log("fetchDataForURLParams", "hasSearched", hasSearched);
      reset();
      const data = location.search;
      const params = qs.parse(data, { ignoreQueryPrefix: true });

      if (params.ncaaPortal === "true") {
        localStorage.setItem("isNcaaPortal", "true");
        setFullAdvancedSearch(true);
        setHasSearched(true);
        return;
      } else {
        localStorage.removeItem("isNcaaPortal");
      }

      let query: PlayerQuery = { states: [] };

      if (params.display) {
        setFullAdvancedSearch(true);
        setHasSearched(true);
      } else if (params.name) {
        setLoading(true);
        let url = QueryHelper.urlForExactName(params.name as string);
        getResults(url);
      } else if (params && Object.keys(params).length > 0) {
        console.log("got params");
        // college
        query = addRangeToQuery(query, params, "classKey");
        query = addRangeToQuery(query, params, "2B");
        query = addRangeToQuery(query, params, "3B");
        query = addRangeToQuery(query, params, "AVG");
        query = addRangeToQuery(query, params, "RBI");
        query = addRangeToQuery(query, params, "BB");
        query = addRangeToQuery(query, params, "OPS");
        query = addRangeToQuery(query, params, "HR");
        query = addRangeToQuery(query, params, "H");
        query = addRangeToQuery(query, params, "HP");
        query = addRangeToQuery(query, params, "GP");
        query = addRangeToQuery(query, params, "AB");
        query = addRangeToQuery(query, params, "SO");
        query = addRangeToQuery(query, params, "CS");
        query = addRangeToQuery(query, params, "R");
        query = addRangeToQuery(query, params, "SLG");
        query = addRangeToQuery(query, params, "OBP");
        query = addRangeToQuery(query, params, "SB");
        query = addRangeToQuery(query, params, "GS");
        query = addRangeToQuery(query, params, "IP");
        query = addRangeToQuery(query, params, "APP");
        query = addRangeToQuery(query, params, "WP");
        query = addRangeToQuery(query, params, "CG");
        query = addRangeToQuery(query, params, "L");
        query = addRangeToQuery(query, params, "SV");
        query = addRangeToQuery(query, params, "OBA");
        query = addRangeToQuery(query, params, "ERA");
        query = addRangeToQuery(query, params, "W");
        query = addRangeToQuery(query, params, "ER");
        query = addRangeToQuery(query, params, "SHO");

        query = addRangeToQuery(query, params, "HBP");
        query = addRangeToQuery(query, params, "PA");
        query = addRangeToQuery(query, params, "K");

        // regular
        query = addRangeToQuery(query, params, "sixtytime");
        query = addRangeToQuery(query, params, "tenyardsplit");
        query = addRangeToQuery(query, params, "catcherpoptime");
        query = addRangeToQuery(query, params, "exitvelocity");
        query = addRangeToQuery(query, params, "positionvelocityc");
        query = addRangeToQuery(query, params, "positionvelocityif");
        query = addRangeToQuery(query, params, "positionvelocityof");
        query = addRangeToQuery(query, params, "maxfastball");
        query = addRangeToQuery(query, params, "curveball");

        query = addRangeToQuery(query, params, "statepositionranking");
        query = addRangeToQuery(query, params, "stateoverallranking");
        query = addRangeToQuery(query, params, "nationalpositionranking");
        query = addRangeToQuery(query, params, "nationaloverallranking");

        query = addRangeToQuery(query, params, "changeup");
        query = addRangeToQuery(query, params, "slider");
        query = addRangeToQuery(query, params, "knuckleball");

        if (params.positions) {
          query = {
            ...query,
            positions: (params.positions as string).split(
              ","
            ) as PlayerQuery["positions"],
          };
          shouldSearch = true;
        }
        if (params.conference) {
          query = {
            ...query,
            conference: params.conference as string,
          };

          shouldSearch = true;
        }
        if (params.summerTeam) {
          query = {
            ...query,
            summerTeam: params.summerTeam as string,
          };

          shouldSearch = true;
        }
        if (params.commitment) {
          query = {
            ...query,
            commitment: params.commitment as string,
          };
          shouldSearch = true;
        }
        if (params.uncommitted) {
          query = {
            ...query,
            uncommitted: params.uncommitted as "Committed" | "Uncommitted",
          };
          shouldSearch = true;
        }

        if (params.university) {
          query = {
            ...query,
            university: params.university as string,
          };
          shouldSearch = true;
        }

        query = addRangeToQuery(query, params, "height");

        query = addRangeToQuery(query, params, "weight");

        query = addRangeToQuery(query, params, "graduatingclass");

        if (params.bat) {
          query = {
            ...query,
            bat: params.bat as string,
          };

          shouldSearch = true;
        }
        if (params.throw) {
          query = {
            ...query,
            throw: params.throw as string,
          };

          shouldSearch = true;
        }

        if (params.states) {
          query = {
            ...query,
            states: (params.states as string).split(","),
          };

          shouldSearch = true;
        }

        if (params.asCollege) {
          query = {
            ...query,
            asCollege: true,
          };
        }

        if (shouldSearch) {
          console.log("shouldSearch");
          setHasSearched(true);
          search(query);
        } else if (query.asCollege) {
          console.log("query.asCollege");
          setHasSearched(true);
          setQuery(query);
        } else {
          console.log("else");
          setHasSearched(true);
        }
      } else {
        console.log("no params");
        setHasSearched(true);
      }
    }
  };

  const exportList = async () => {
    // if (!currentSortType) {
    //   setCurrentSortType("Player");
    // }
    // let sortby = query.asCollege
    //   ? COLLEGE_SORT_MAP[currentSortType]
    //   : SORT_MAP[currentSortType];

    // let uncommitedResults = results.filter(
    //   (item) => (item.college_commitment == "Uncommited") == true
    // );

    // let doUncommited =
    //   sortby == "college_commitment.keyword" && uncommitedResults.length > 0;

    let res = await QueryHelper.getAllForURL(url);

    let data = [];
    res.data.forEach((player) => {
      data.push({
        "FIRST NAME": player.first_name,
        "LAST NAME": player.last_name,
        CLASS: player.graduating_class,
        POSITION: player.position,
        "HIGH SCHOOL": player.high_school,
        STATE: player.state,
        BAT: player.bats,
        THROW: player.throws,
        HEIGHT: player.height,
        WEIGHT: player.weight,
        TWITTER: player.twitter_handle,
        "PHONE NUMBER": player.contact_phone,
        //"EMAIL": player.email,
        "SUMMER TEAM": player.summer_team,
      });
    });

    console.log(Object.keys(data[0]));
    //@ts-ignore
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    const widths = Object.keys(data[0]).map((key) =>
      Math.max(key.length, 10, (data[0][key] || "").length)
    );
    console.log(widths);
    worksheet["!cols"] = widths.map((width) => ({ wch: width }));
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `player-export.xlsx`);
  };

  const canLoadMore = () => {
    return (
      url && url !== "" && count && results?.length < count + secondaryCount - 2
    );
  };

  const loadMore = async () => {
    setLoading(true);
    if (!currentSortType) {
      //@ts-ignore
      setCurrentSortType("Player");
    }
    //@ts-ignore
    let sortby = query.asCollege
      ? COLLEGE_SORT_MAP[currentSortType]
      : SORT_MAP[currentSortType];

    let uncommitedResults = results.filter(
      (item) => (item.college_commitment == "Uncommited") == true
    );

    let doUncommited =
      sortby == "college_commitment.keyword" && uncommitedResults.length > 0;

    let res = await QueryHelper.loadMoreForURL(
      url,
      doUncommited ? uncommitedResults.length + 1 : results?.length + 1,
      sortby,
      currentSortDirection === "asc",
      doUncommited
    );

    if (!doUncommited && res && res.data && res.data.length < 20) {
      let resTwo = await QueryHelper.loadMoreForURL(
        url,
        0,
        sortby,
        currentSortDirection === "asc",
        true
      );
      finishMore([...res.data, ...resTwo.data], res.count, resTwo.count);
    } else {
      finishMore(
        res.data,
        doUncommited ? null : res.count,
        doUncommited ? secondaryCount : null
      );
    }
  };

  const finishMore = (data, count, secondaryCount) => {
    setResults([...results, ...data]);
    if (secondaryCount) {
      setSecondaryCount(secondaryCount);
    }
    if (count) {
      setCount(count);
    }
    setLoading(false);
  };

  const search = async (query: PlayerQuery) => {
    setQuery(query);
    let url = QueryHelper.urlForQuery(query);
    getResults(url);
  };

  const getResults = async (url: string) => {
    setHasSearched(true);
    if (fullAdvancedSearch) {
      setFullAdvancedSearch(false);
      removeQuery();
    }
    setLoading(true);
    console.log("url", url);
    setUrl(url);
    let res = await QueryHelper.fetchDataForURLwithCount(url);
    if (res && res.data) {
      setResults(res.data);
      setCount(res.count);
      setSecondaryCount(0);
    }

    removeQuery();
  };

  const removeQuery = () => {
    setLoading(false);
  };

  if (fullAdvancedSearch) {
    return (
      <div
        style={{
          minHeight: "92.7vh",
          marginTop: "-1.1rem",
          minWidth: "100vw",
          paddingTop: "3rem",
          display: "flex",
          flexDirection: "column",
          backgroundColor: colors.accent,
        }}
      >
        <AdvancedSearch
          query={query}
          isFull={true}
          setQuery={setQuery}
          sendUrl={(url) => {
            setUrl(url);
            getResults(url);
          }}
          setShowAdvanced={setShowAdvanced}
          save={(query) => {
            setQuery(query);
            let url = QueryHelper.urlForQuery(query);
            setUrl(url);
            setShowModal(true);
          }}
          clear={() => {
            setResults([]);
            setCount(0);
            setSecondaryCount(0);
            setUrl("");
            setCurrentSortDirection(null);
            setCurrentSortType(null);
          }}
          setLoading={setLoading}
        />
      </div>
    );
  }

  return (
    <div className="search-container" style={{ minHeight: "95vh" }}>
      {showAdvanced ? (
        <AdvancedSearch
          isFull={false}
          query={query}
          setQuery={setQuery}
          sendUrl={(url) => {
            setUrl(url);
            getResults(url);
          }}
          setShowAdvanced={setShowAdvanced}
          save={(query) => {
            setQuery(query);
            let url = QueryHelper.urlForQuery(query);
            setUrl(url);
            setShowModal(true);
          }}
          clear={() => {
            setResults([]);
            setCount(0);
            setSecondaryCount(0);
            setUrl("");
            setCurrentSortDirection(null);
            setCurrentSortType(null);
          }}
          setLoading={setLoading}
        />
      ) : (
        <div />
      )}

      {showAdvanced ? (
        <div />
      ) : (
        <Button
          onClick={() => setShowAdvanced(!showAdvanced)}
          style={{
            height: 40,
            minHeight: 40,
            borderRadius: 20,
            position: "absolute",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            top: "4.5rem",
            left: "1rem",
            backgroundColor: colors.accent,
          }}
        >
          <AiOutlineSearch color={colors.white} />
        </Button>
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "85vh",
          marginLeft: "1rem",
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 10,
          flex: 1,
          maxWidth: "95vw",
          overflowX: "scroll",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <ScoutTitle
            text="RESULTS"
            withHelp={true}
            onClick={() => setShowHelp(true)}
          />
          <UnderlineButton
            text="export list"
            color={colors.accent}
            onClick={() => exportList()}
          />
        </div>

        {results?.length > 0 ? (
          <ResultHeader
            disabledMode={flagMode}
            currentSortType={currentSortType}
            setCurrentSortType={setCurrentSortType}
            currentSortDirection={currentSortDirection}
            setCurrentSortDirection={setCurrentSortDirection}
            handleSort={handleSort}
            onlyCollege={query.asCollege}
          />
        ) : (
          <div />
        )}

        {/* tara here export  */}

        {results.length > 0 ? (
          (results || []).map((player, index) => {
            return (
              <ResultItem
                player={player}
                index={index}
                onlyCollege={query.asCollege}
                shouldLoad={false}
                disabledMode={flagMode}
              />
            );
          })
        ) : (
          <p
            style={{
              fontStyle: "italic",
              opacity: 0.8,
              marginTop: 100,
              textAlign: "center",
            }}
          >
            {loading ? "" : "No results."}
          </p>
        )}

        {canLoadMore() ? (
          <div
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "77vw",
              display: "flex",
            }}
          >
            <UnderlineButton
              onClick={() => {
                loadMore();
              }}
              text="load more"
            />
          </div>
        ) : (
          <div />
        )}
      </div>

      {loading ? (
        <div
          style={{
            position: "absolute",
            left: showAdvanced ? "63vw" : "47vw",
            top: "30vh",
          }}
        >
          <Spinner animation="border" />
        </div>
      ) : (
        <div />
      )}

      <SaveSearchModal
        onHide={() => setShowModal(false)}
        url={url}
        count={count}
        //@ts-ignore
        position={
          query && query.positions && query.positions.length > 0
            ? query.positions[0]
            : ""
        }
        query={query}
        show={showModal}
      />
      <HelpModal
        show={showHelp}
        kind={"SEARCH"}
        onHide={() => setShowHelp(false)}
      />
    </div>
  );
}

const enhance = compose(withFirestore);

export default enhance(SearchResults);
