import { useMe } from "../hooks/users";
import colors from "constants/colors";
// import { User } from "models/User";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useFirestore } from "react-redux-firebase";
import { toast } from "react-toastify";
import { trackModalOpen, trackModalClose } from "../hooks/usePageTracking";

export default function CreateBigBoardModal(props: { onHide: any; show: any }) {
  const me = useMe();
  const firestore = useFirestore();
  const [name, setName] = useState("");

  useEffect(() => {
    if (props.show) {
      trackModalOpen("Create Big Board Modal");
    }
    return () => {
      if (props.show) {
        trackModalClose();
      }
    };
  }, [props.show]);

  const save = async () => {
    let upd = {
      name: name,
      isSecondary: true,
      ownerId: me.id,
      ownerName: `${me.firstName} ${me.lastName}`,
      recipientIds: [me.id],
    };

    let res = await firestore.collection("boards").add(upd);
    let boardId = res.id;

    toast.dark(`Board added!`, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
    });

    props.onHide({ ...upd, id: boardId });
  };

  return (
    <Modal
      {...props}
      //@ts-ignore
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        style={{
          color: colors.white,
          backgroundColor: colors.accent,
          borderBottomWidth: 0,
        }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          Create a Big Board
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          color: colors.white,
          backgroundColor: colors.accent,
          display: "flex",
          flexDirection: "column",
          paddingTop: 0,
          marginTop: 0,
        }}
        className="searching"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <input
            type="text"
            style={{
              marginBottom: 10,
              display: "flex",
              flex: 1,
              border: "0px solid",
              color: "#fff",
              width: 400,
              backgroundColor: "transparent",
              borderBottom: "2px solid #fff",
            }}
            value={name}
            placeholder="Board Name"
            onChange={(evt) => setName(evt.target.value)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => props.onHide(null)}
          style={{
            borderColor: colors.accent,
            backgroundColor: colors.white,
            color: colors.accent,
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            save();
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
