/*****************************************************************************
 * Import
 *****************************************************************************/
import React from "react";
import colors from "constants/colors";
import { useHistory, useLocation } from "react-router-dom";
import {
  useFirebase,
  useFirestore,
  useFirestoreConnect,
} from "react-redux-firebase";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

import GoogleLogoIcon from "assets/g-logo.png";
import { useFlags } from "hooks/flags";
import { toast } from "react-toastify";

/*****************************************************************************
 * Public Components
 *****************************************************************************/

export const GoogleLoginButton = ({ onCreateNewUser }) => {
  const firebaseInstance = useFirebase();
  const firestore = useFirestore();
  let history = useHistory();
  const flags = useFlags();

  const googleSignUp = async () => {
    console.log("GOOGLE SIGN UP");
    var provider = new firebase.auth.GoogleAuthProvider();
    await firebaseInstance
      .auth()
      .signInWithPopup(provider)
      .then(async (result) => {
        var credential = result.credential;

        var user = result.user;
        var userId = user.uid;
        var fullName = user.displayName;
        var nameParts = fullName.split(" ");
        var email = user.email;

        let relatedUser = await firestore.collection("users").doc(userId).get();

        if (relatedUser?.exists) {
          console.log("USER EXISTS");
          history.replace("/");
        } else {
          console.log("NEW USER");
          if (flags.force_edu_login && !email.endsWith(".edu")) {
            toast.dark(
              "Currently only accepting sign-ups from accounts with .edu domains!",
              {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
              }
            );
          } else {
            onCreateNewUser({
              email: email,
              firstName: nameParts[0],
              lastName: nameParts.length > 1 ? nameParts[1] : "",
              timeCreated: new Date(),
              lastUpdated: new Date(),
              //@ts-ignore
              googleAccessToken: credential.accessToken,
              userId: userId,
            });
          }
        }
      })
      .catch((error) => {
        var errorMessage = error.message;
        console.log("err", errorMessage);
      });
  };

  return (
    <SSOButton
      iconSrc={GoogleLogoIcon}
      text="Sign in with Google"
      onClick={googleSignUp}
    />
  );
};

/*****************************************************************************
 * Helper Components
 *****************************************************************************/

const SSOButton = ({ iconSrc, text, onClick }) => {
  return (
    <div
      //className="hover-opacity-30"
      onClick={onClick}
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "30px",
        padding: "8px",
        width: "100%",
        border: `2px solid ${colors.accent}`,
        borderRadius: "5px",
        cursor: "pointer",
      }}
    >
      <img src={iconSrc} style={{ width: "24px", height: "24px" }} />
      <div>{text}</div>
      <div style={{ width: "24px" }} />
    </div>
  );
};
