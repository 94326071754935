import React, { useEffect, useState } from "react";
import "./search-results.css";
import { EMPTY_QUERY } from "models/Query";
import RangeSelector from "./components/range-selector";
import SmallButton from "./components/small-button";
import { toast } from "react-toastify";
import Switch from "react-switch";
import {
  AiOutlineCloseCircle,
  AiFillCaretRight,
  AiFillCaretDown,
} from "react-icons/ai";
import { MdChevronRight } from "react-icons/md";
import { STATE_MAP } from "constants/data";
import { Button } from "react-bootstrap";
import {
  COLLEGE_TITLES,
  KEY_FOR_TITLE,
  MAXES,
  MINS,
  RANGE_TITLES,
  STEPS,
  PLAYER_TITLES,
  TITLE_FOR_KEY,
} from "constants/search";
import TeamDropdown from "../../dropdowns/team-dropdown";
import StateDropdown from "../../dropdowns/state-dropdown";
import MediumButton from "./components/medium-button";
import colors from "constants/colors";
import { useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import moment from "moment";
import CollegeCommitmentDropdown from "dropdowns/college-commitment-dropdown";
import { Link, useHistory } from "react-router-dom";
import UniversityDropdown from "dropdowns/university-dropdown";
import ConferenceDropwdown from "dropdowns/conference-dropdown";
import Select from "react-select";
import UnderlineButton from "shared/underline-button";
import EditSavedSearchModal from "modals/edit-saved-search-modal";
import { useFlagMode } from "shared/useFlagMode";
import PortalSearch from "./components/PortalSearch";

function AdvancedSearch(props: {
  query: any;
  setQuery: any;
  sendUrl: any;
  clear: any;
  setShowAdvanced: any;
  save: any;
  setLoading: any;
  isFull: boolean;
  minimal?: boolean;
}) {
  const userId = useSelector((state: any) => state.firebase.auth.uid);
  const [visible, setVisible] = useState<string[]>([]);
  const [editSavedSearch, setEditSavedSearch] = React.useState(null);
  const [showSaved, setShowSaved] = useState(false);
  const [isNCAA, setIsNCAA] = useState(false);
  const mySearches = useSelector(
    (state: any) => state.firestore.data.mySearches
  );
  const history = useHistory();
  const flagMode = useFlagMode();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const isNcaaPortal = searchParams.get("ncaaPortal") === "true";
    setIsNCAA(isNcaaPortal);
  }, [window.location.search]);

  const filteredTitles = PLAYER_TITLES.filter((item) => {
    const flaggedTitles = [
      "National Overall Ranking",
      "National Position Ranking",
      "State Overall Ranking",
      "State Position Ranking",
    ];
    return !flaggedTitles.includes(item) || flagMode !== "both";
  });

  useFirestoreConnect([
    {
      collection: "users",
      doc: userId,
      subcollections: [{ collection: "searches" }],
      storeAs: `mySearches`,
    },
  ]);

  const filteredSavedSearches = () => {
    let saved = Object.keys(mySearches || {})
      .map((key) => (mySearches ? { ...mySearches[key], id: key } : null))
      .filter((item) => item !== null);
    if (!isNCAA) {
      saved = saved.filter((item) => !item.ncaaPortal);
    }

    return saved;
  };

  const getSelectedFromQuery = () => {
    let querySelected = Object.keys(props.query)
      .filter(
        (item) =>
          props.query[item] &&
          props.query[item] !== null &&
          (item !== "states" || props.query["states"].length > 0) &&
          item !== "asCollege" &&
          item !== "commitment"
      )
      .map((item) => TITLE_FOR_KEY[item]);

    return [
      ...querySelected,
      ...visible.filter((item) => !querySelected.includes(item)),
    ];
  };

  const getDropdownOptions = () => {
    if (props.query.asCollege) {
      return COLLEGE_TITLES.map((item) => ({
        value: item,
        label: item,
      }));
    } else {
      return filteredTitles.map((item) => ({
        value: item,
        label: item,
      }));
    }
  };

  const renderPositionButtons = () => {
    let selected = getSelectedFromQuery();
    if (!selected.includes("Position")) {
      return <div />;
    }
    if (props.query.asCollege) {
      return (
        <div className="position-outer">
          {renderTitle("Position")}
          <div
            style={{ flexDirection: "row", display: "flex", marginBottom: 10 }}
          >
            <MediumButton
              title="Pitcher"
              selected={(props.query.positions || []).includes("Pitcher")}
              onClick={() => {
                let currPositions = props.query.positions || [];
                if (currPositions.includes("Pitcher")) {
                  props.setQuery({
                    ...props.query,
                    positions: currPositions.filter(
                      (item) => item !== "Pitcher"
                    ),
                  });
                } else {
                  props.setQuery({
                    ...props.query,
                    positions: [...currPositions, "Pitcher"],
                  });
                }
              }}
            />
            <MediumButton
              title="Catcher"
              selected={(props.query.positions || []).includes("Catcher")}
              onClick={() => {
                let currPositions = props.query.positions || [];
                if (currPositions.includes("Catcher")) {
                  props.setQuery({
                    ...props.query,
                    positions: currPositions.filter(
                      (item) => item !== "Catcher"
                    ),
                  });
                } else {
                  props.setQuery({
                    ...props.query,
                    positions: [...currPositions, "Catcher"],
                  });
                }
              }}
            />
          </div>
          <div
            style={{ flexDirection: "row", display: "flex", marginBottom: 10 }}
          >
            <MediumButton
              title="Infield"
              selected={(props.query.positions || []).includes("Infield")}
              onClick={() => {
                let currPositions = props.query.positions || [];
                if (currPositions.includes("Infield")) {
                  props.setQuery({
                    ...props.query,
                    positions: currPositions.filter(
                      (item) => item !== "Infield"
                    ),
                  });
                } else {
                  props.setQuery({
                    ...props.query,
                    positions: [...currPositions, "Infield"],
                  });
                }
              }}
            />
            <MediumButton
              title="Outfield"
              selected={(props.query.positions || []).includes("Outfield")}
              onClick={() => {
                let currPositions = props.query.positions || [];
                if (currPositions.includes("Outfield")) {
                  props.setQuery({
                    ...props.query,
                    positions: currPositions.filter(
                      (item) => item !== "Outfield"
                    ),
                  });
                } else {
                  props.setQuery({
                    ...props.query,
                    positions: [...currPositions, "Outfield"],
                  });
                }
              }}
            />
          </div>
          <div style={{ flexDirection: "row", display: "flex" }}>
            <MediumButton
              title="First Base"
              selected={(props.query.positions || []).includes("First Base")}
              onClick={() => {
                let currPositions = props.query.positions || [];
                if (currPositions.includes("First Base")) {
                  props.setQuery({
                    ...props.query,
                    positions: currPositions.filter(
                      (item) => item !== "First Base"
                    ),
                  });
                } else {
                  props.setQuery({
                    ...props.query,
                    positions: [...currPositions, "First Base"],
                  });
                }
              }}
            />
            <MediumButton
              title="Utility"
              selected={(props.query.positions || []).includes("Utility")}
              onClick={() => {
                let currPositions = props.query.positions || [];
                if (currPositions.includes("Utility")) {
                  props.setQuery({
                    ...props.query,
                    positions: currPositions.filter(
                      (item) => item !== "Utility"
                    ),
                  });
                } else {
                  props.setQuery({
                    ...props.query,
                    positions: [...currPositions, "Utility"],
                  });
                }
              }}
            />
          </div>
        </div>
      );
    }
    return (
      <div className="position-outer">
        {renderTitle("Position")}
        <div className="position-row">
          <SmallButton
            title="RHP"
            selected={(props.query.positions || []).includes("RHP")}
            onClick={() => {
              let currPositions = props.query.positions || [];
              if (currPositions.includes("RHP")) {
                props.setQuery({
                  ...props.query,
                  positions: currPositions.filter((item) => item !== "RHP"),
                });
              } else {
                props.setQuery({
                  ...props.query,
                  positions: [...currPositions, "RHP"],
                });
              }
            }}
          />
          <SmallButton
            title="LHP"
            selected={(props.query.positions || []).includes("LHP")}
            onClick={() => {
              let currPositions = props.query.positions || [];
              if (currPositions.includes("LHP")) {
                props.setQuery({
                  ...props.query,
                  positions: currPositions.filter((item) => item !== "LHP"),
                });
              } else {
                props.setQuery({
                  ...props.query,
                  positions: [...currPositions, "LHP"],
                });
              }
            }}
          />
          <SmallButton
            title="1B"
            selected={(props.query.positions || []).includes("1B")}
            onClick={() => {
              let currPositions = props.query.positions || [];
              if (currPositions.includes("1B")) {
                props.setQuery({
                  ...props.query,
                  positions: currPositions.filter((item) => item !== "1B"),
                });
              } else {
                props.setQuery({
                  ...props.query,
                  positions: [...currPositions, "1B"],
                });
              }
            }}
          />
        </div>
        <div className="position-row">
          <SmallButton
            title="2B"
            selected={(props.query.positions || []).includes("2B")}
            onClick={() => {
              let currPositions = props.query.positions || [];
              if (currPositions.includes("2B")) {
                props.setQuery({
                  ...props.query,
                  positions: currPositions.filter((item) => item !== "2B"),
                });
              } else {
                props.setQuery({
                  ...props.query,
                  positions: [...currPositions, "2B"],
                });
              }
            }}
          />
          <SmallButton
            title="SS"
            selected={(props.query.positions || []).includes("SS")}
            onClick={() => {
              let currPositions = props.query.positions || [];
              if (currPositions.includes("SS")) {
                props.setQuery({
                  ...props.query,
                  positions: currPositions.filter((item) => item !== "SS"),
                });
              } else {
                props.setQuery({
                  ...props.query,
                  positions: [...currPositions, "SS"],
                });
              }
            }}
          />
          <SmallButton
            title="3B"
            selected={(props.query.positions || []).includes("3B")}
            onClick={() => {
              let currPositions = props.query.positions || [];
              if (currPositions.includes("3B")) {
                props.setQuery({
                  ...props.query,
                  positions: currPositions.filter((item) => item !== "3B"),
                });
              } else {
                props.setQuery({
                  ...props.query,
                  positions: [...currPositions, "3B"],
                });
              }
            }}
          />
        </div>
        <div className="position-row">
          <SmallButton
            title="C"
            selected={(props.query.positions || []).includes("C")}
            onClick={() => {
              let currPositions = props.query.positions || [];
              if (currPositions.includes("C")) {
                props.setQuery({
                  ...props.query,
                  positions: currPositions.filter((item) => item !== "C"),
                });
              } else {
                props.setQuery({
                  ...props.query,
                  positions: [...currPositions, "C"],
                });
              }
            }}
          />
          <SmallButton
            title="OF"
            selected={(props.query.positions || []).includes("OF")}
            onClick={() => {
              let currPositions = props.query.positions || [];
              if (currPositions.includes("OF")) {
                props.setQuery({
                  ...props.query,
                  positions: currPositions.filter((item) => item !== "OF"),
                });
              } else {
                props.setQuery({
                  ...props.query,
                  positions: [...currPositions, "OF"],
                });
              }
            }}
          />
          <SmallButton
            title="MIF"
            selected={(props.query.positions || []).includes("MIF")}
            onClick={() => {
              let currPositions = props.query.positions || [];
              if (currPositions.includes("MIF")) {
                props.setQuery({
                  ...props.query,
                  positions: currPositions.filter((item) => item !== "MIF"),
                });
              } else {
                props.setQuery({
                  ...props.query,
                  positions: [...currPositions, "MIF"],
                });
              }
            }}
          />
        </div>
      </div>
    );
  };

  const renderRange = (name) => {
    let key = KEY_FOR_TITLE[name];

    return getSelectedFromQuery().includes(name) ? (
      <div>
        {renderTitle(name)}
        <RangeSelector
          units={
            name === "Weight"
              ? "lbs"
              : name === "Height"
              ? "in"
              : name === "Academic Class"
              ? "collegeClass"
              : ""
          }
          valmax={parseFloat(props.query[key].max)}
          valmin={parseFloat(props.query[key].min)}
          setValues={(vals) => {
            let key = KEY_FOR_TITLE[name];
            let q = props.query;
            props.setQuery({
              ...q,
              [key]: {
                min: vals[0],
                max: vals[1],
              },
            });
          }}
          min={MINS[name]}
          max={MAXES[name]}
          step={STEPS[name]}
        />
      </div>
    ) : (
      <div />
    );
  };

  const addChip = (name) => {
    if (RANGE_TITLES.includes(name)) {
      let key = KEY_FOR_TITLE[name];
      if (!Object.keys(props.query).includes(key)) {
        let q = props.query;

        props.setQuery({
          ...q,
          [key]: {
            min: MINS[name],
            max: MAXES[name],
          },
        });
      }
    } else if (name === "Commitment") {
      let q = props.query;

      props.setQuery({
        ...q,
        uncommitted: "Uncommitted",
      });
    } else {
      setVisible([...visible.filter((item) => item !== name), name]);
    }
  };

  const removeChip = (name) => {
    if (name === "Commitment") {
      let q = props.query;
      delete q.uncommitted;
      delete q.commitment;
      props.setQuery(q);
    } else if (name !== "State") {
      let key = KEY_FOR_TITLE[name];
      let q = props.query;
      delete q[key];
      props.setQuery(q);
    } else {
      props.setQuery({ ...props.query, states: [] });
    }
    setVisible(visible.filter((item) => item !== name));
  };

  const renderTitle = (name) => {
    return (
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: colors.white,
        }}
      >
        <p className="position-title">{name.toUpperCase()}</p>
        <AiOutlineCloseCircle
          onClick={() => {
            removeChip(name);
          }}
          style={{ width: 20, height: 20, marginLeft: 6 }}
        />
      </div>
    );
  };

  const renderSummerTeam = () => {
    return getSelectedFromQuery().includes("Summer Team") ? (
      <div className="summer-container">
        {renderTitle("Summer Team")}

        <TeamDropdown
          placeholder={props.query.summerTeam ? props.query.summerTeam : null}
          selectTeam={(val) =>
            props.setQuery({ ...props.query, summerTeam: val })
          }
        />
      </div>
    ) : (
      <div />
    );
  };

  const renderStates = () => {
    return getSelectedFromQuery().includes("State") ? (
      <div className="summer-container">
        {renderTitle("State")}
        {/* */}
        <StateDropdown
          selectStates={(vals) =>
            props.setQuery({
              ...props.query,
              states: vals.map((item) => STATE_MAP[item]),
            })
          }
        />
      </div>
    ) : (
      <div />
    );
  };

  const renderUniversity = () => {
    return getSelectedFromQuery().includes("University") ? (
      <div className="summer-container">
        {renderTitle("University")}

        <UniversityDropdown
          selectTeam={(val) =>
            props.setQuery({ ...props.query, university: val })
          }
        />
      </div>
    ) : (
      <div />
    );
  };

  const renderConference = () => {
    return getSelectedFromQuery().includes("Conference") ? (
      <div className="summer-container">
        {renderTitle("Conference")}

        <ConferenceDropwdown
          allowAll={false}
          selectItem={(val) =>
            props.setQuery({ ...props.query, conference: val })
          }
        />
      </div>
    ) : (
      <div />
    );
  };

  const renderCommitment = () => {
    return getSelectedFromQuery().includes("Commitment") ? (
      <div>
        {renderTitle("Commitment")}
        <div style={{ flexDirection: "row", display: "flex" }}>
          <MediumButton
            title="Commited"
            selected={props.query.uncommitted === "Committed"}
            onClick={() =>
              props.setQuery({ ...props.query, uncommitted: "Committed" })
            }
          />
          <MediumButton
            title="Uncommited"
            selected={props.query.uncommitted === "Uncommitted"}
            onClick={() =>
              props.setQuery({ ...props.query, uncommitted: "Uncommitted" })
            }
          />
        </div>
        {props.query.uncommitted === "Committed" ? (
          <div className="summer-container">
            <CollegeCommitmentDropdown
              placeholder={
                props.query.commitment ? props.query.commitment : null
              }
              selectCollege={(val) =>
                props.setQuery({ ...props.query, commitment: val })
              }
            />
          </div>
        ) : (
          <div />
        )}
      </div>
    ) : (
      <div></div>
    );
  };

  const renderBats = () => {
    return getSelectedFromQuery().includes("Bats") ? (
      <div className="tile-outer">
        {renderTitle("Bats")}
        <div className="position-row">
          <SmallButton
            title="R"
            selected={props.query.bat === "R"}
            onClick={() => props.setQuery({ ...props.query, bat: "R" })}
          />
          <SmallButton
            title="L"
            selected={props.query.bat === "L"}
            onClick={() => props.setQuery({ ...props.query, bat: "L" })}
          />
          <SmallButton
            title="S"
            selected={props.query.bat === "S"}
            onClick={() => props.setQuery({ ...props.query, bat: "S" })}
          />
        </div>
      </div>
    ) : (
      <div />
    );
  };

  const renderThrows = () => {
    return getSelectedFromQuery().includes("Throws") ? (
      <div className="tile-outer">
        {renderTitle("Throws")}
        <div className="position-row">
          <SmallButton
            title="R"
            selected={props.query.throw === "R"}
            onClick={() => props.setQuery({ ...props.query, throw: "R" })}
          />
          <SmallButton
            title="L"
            selected={props.query.throw === "L"}
            onClick={() => props.setQuery({ ...props.query, throw: "L" })}
          />
        </div>
      </div>
    ) : (
      <div />
    );
  };

  const queryAsString = () => {
    var str = [];
    for (var p in props.query)
      if (props.query.hasOwnProperty(p)) {
        if (RANGE_TITLES.includes(TITLE_FOR_KEY[p])) {
          str.push(p + "=" + props.query[p].min + "," + props.query[p].max);
        } else if (p === "states" || p === "positions") {
          if (props.query[p].length > 0) {
            str.push(p + "=" + props.query[p].join(","));
          }
        } else {
          str.push(p + "=" + props.query[p]);
        }
      }
    return str.join("&");
  };

  const toggleCollege = () => {
    let str = "/search-results?asCollege=true";
    if (props.query.asCollege) {
      str = "/search-results";
    }
    props.setQuery(EMPTY_QUERY);
    history.push(str);
    props.clear();
  };

  const isNCAAPortal = () => {
    let str = "/search-results?ncaaPortal=true#/";
    props.setQuery(EMPTY_QUERY);
    setVisible([]);
    setIsNCAA(true);
    history.push(str);
    props.clear();
  };

  const advancedSearch = () => {
    let str = "/search-results?display=full";
    props.setQuery(EMPTY_QUERY);
    setVisible([]);
    setIsNCAA(false);
    history.push(str);
  };

  const onChange = (items, { action, removedValue, option }) => {
    switch (action) {
      case "select-option":
        if (option) {
          addChip(option.value);
        }

      case "remove-value":
      case "pop-value":
        if (removedValue) {
          removeChip(removedValue.value);
        }
    }
  };

  const customStyles = {
    option: (provided) => ({
      ...provided,
      color: "black",
    }),
    control: (provided, state) => ({
      ...provided,
      minWidth: 280,
      maxWidth: 315,
    }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: colors.lightBlue,
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: colors.white,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: colors.white,
      ":hover": {
        backgroundColor: colors.accent,
      },
    }),
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "center",
        marginRight: 12,
        padding: 12,
        paddingTop: 40,
        minWidth: 344,
        backgroundColor: "#003366",
      }}
    >
      <div className="advanced-container">
        <div
          style={{
            flexDirection: "column",
            display: "flex",
          }}
        >
          {!props.minimal && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: 8,
              }}
            >
              <h4
                onClick={() => advancedSearch()}
                style={{
                  fontWeight: 300,
                  fontSize: 20,
                  color: colors.white,
                  textAlign: "center",
                  borderBottom: !isNCAA && "2px solid #fff",
                  cursor: "pointer",
                  padding: 4,
                }}
              >
                ADVANCED SEARCH
              </h4>
              {props.isFull && (
                <h4
                  onClick={() => isNCAAPortal()}
                  style={{
                    fontWeight: 300,
                    fontSize: 20,
                    color: colors.white,
                    textAlign: "center",
                    borderBottom: isNCAA && "2px solid #fff",
                    cursor: "pointer",
                    padding: 4,
                  }}
                >
                  NCAA PORTAL
                </h4>
              )}
            </div>
          )}
          {!props.minimal && !isNCAA && (
            <div
              style={{
                marginTop: 8,
                marginBottom: 8,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <p
                style={{
                  color: colors.white,
                  marginRight: 8,
                  fontWeight: "bold",
                }}
              >
                COLLEGE DATA
              </p>
              <Switch
                checked={props.query.asCollege}
                onChange={() => toggleCollege()}
                uncheckedIcon={false}
                checkedIcon={false}
                onColor={colors.lightBlue}
              />
            </div>
          )}
          {!isNCAA ? (
            <Select
              options={getDropdownOptions()}
              isMulti={true}
              isClearable={false}
              onChange={onChange}
              value={getSelectedFromQuery().map((item) => ({
                value: item,
                label: item,
              }))}
              styles={customStyles}
            />
          ) : (
            <PortalSearch isNCAA={isNCAA} setIsNCAA={setIsNCAA} />
          )}
        </div>
        {props.isFull || props.minimal ? (
          <div />
        ) : (
          <Button
            style={{
              color: "white",
              backgroundColor: "transparent",
              padding: 6,
              borderWidth: 0,
              boxShadow: "none",
            }}
            onClick={() => props.setShowAdvanced(false)}
          >
            <p
              style={{
                color: "#fff",
                textAlign: "center",
                opacity: 0.6,
                textDecorationColor: "#fff",
                textDecorationLine: "underline",
                textDecorationStyle: "solid",
              }}
            >
              hide advanced
            </p>
          </Button>
        )}
        {renderPositionButtons()}
        {renderRange("Academic Class")}
        {renderRange("Graduating Class")}
        {renderConference()}
        {renderUniversity()}
        {renderCommitment()}
        {renderRange("AVG")}
        {renderRange("HR")}

        {renderRange("Height")}
        {renderRange("Weight")}

        {renderSummerTeam()}
        {renderStates()}

        {renderBats()}
        {renderThrows()}

        {renderRange("National Overall Ranking")}
        {renderRange("National Position Ranking")}
        {renderRange("State Overall Ranking")}
        {renderRange("State Position Ranking")}

        {renderRange("Fastball")}
        {renderRange("Curveball")}
        {renderRange("Changeup")}
        {renderRange("Slider")}
        {renderRange("Cutter")}
        {renderRange("Knuckleball")}
        {renderRange("60 Time")}
        {renderRange("10 Yard Split")}
        {renderRange("Catcher Pop Time")}
        {renderRange("Exit Velocity")}
        {renderRange("Position Velocity")}
        {renderRange("Position Velocity (IF)")}
        {renderRange("Position Velocity (OF)")}

        {renderRange("2B")}
        {renderRange("3B")}
        {renderRange("OBP")}
        {renderRange("SLG")}
        {renderRange("OPS")}
        {renderRange("GP")}
        {renderRange("AB")}
        {renderRange("R")}
        {renderRange("H")}

        {renderRange("RBI")}
        {renderRange("HP")}
        {renderRange("BB")}
        {renderRange("SO")}
        {renderRange("SB")}
        {renderRange("CS")}
        {renderRange("GS")}
        {renderRange("IP")}
        {renderRange("APP")}
        {renderRange("WP")}
        {renderRange("CG")}
        {renderRange("L")}
        {renderRange("SV")}
        {renderRange("OBA")}
        {renderRange("ERA")}
        {renderRange("W")}
        {renderRange("ER")}
        {renderRange("SHO")}
        {renderRange("HBP")}
        {renderRange("PA")}
        {renderRange("K")}
      </div>
      {getSelectedFromQuery().length > 0 && !props.minimal ? (
        <div
          style={{
            marginTop: 20,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Link to={`/search-results?${queryAsString()}`}>
            <div className={"search-button"}>
              <p style={{ color: "#003366", fontSize: 20 }}>SEARCH</p>
            </div>
          </Link>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              width: "100%",
              marginTop: 4,
            }}
          >
            <Button
              onClick={() => {
                if (
                  getSelectedFromQuery().includes("Position") &&
                  props.query.position !== null
                ) {
                  props.save(props.query);
                } else {
                  toast.dark(
                    `Please add a position filter to save this search.`,
                    {
                      position: "top-right",
                      autoClose: 3000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                    }
                  );
                }
              }}
              style={{
                color: "white",
                backgroundColor: "transparent",
                padding: 6,
                borderWidth: 0,
                boxShadow: "none",
              }}
            >
              <p
                style={{
                  color: "#fff",
                  textAlign: "center",
                  // opacity: 0.6,
                  textDecorationColor: "#fff",
                  textDecorationLine: "underline",
                  textDecorationStyle: "solid",
                }}
              >
                save search
              </p>
            </Button>

            <div
              style={{
                color: "white",
                backgroundColor: "transparent",
                padding: 6,
                borderWidth: 0,
                boxShadow: "none",
              }}
              onClick={() => {
                props.setQuery(EMPTY_QUERY);
                setVisible([]);
                history.push("/search-results");
                props.clear();
              }}
            >
              <p
                style={{
                  color: "#fff",
                  textAlign: "center",
                  opacity: 0.6,
                  textDecorationColor: "#fff",
                  textDecorationLine: "underline",
                  textDecorationStyle: "solid",
                }}
              >
                clear results
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div />
      )}

      {!props.minimal && !isNCAA && (
        <div
          style={{
            width: "90%",
            marginLeft: "5%",
            height: 1,
            backgroundColor: "#fff",
            marginTop: 25,
            marginBottom: 25,
          }}
        />
      )}
      {!props.minimal && !isNCAA && (
        <div>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <h4 style={{ fontWeight: 300, fontSize: 20, color: colors.white }}>
              MY SAVED SEARCHES
            </h4>
            <Button
              variant="secondary"
              style={{
                marginTop: -12,
                color: "white",
                fontSize: 30,
                backgroundColor: "transparent",
                padding: 6,
                borderWidth: 0,
                boxShadow: "none",
              }}
              onClick={() => setShowSaved(!showSaved)}
            >
              {showSaved ? <AiFillCaretDown /> : <AiFillCaretRight />}
            </Button>
          </div>
          {showSaved ? (
            <div>
              {filteredSavedSearches().map((item, index) => {
                return item && item.id && item.name ? (
                  item.webUrl ? (
                    <div
                      key={index}
                      style={{
                        padding: 8,
                        backgroundColor: colors.white,
                        color: colors.accent,
                        borderRadius: 5,
                        marginBottom: 5,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Link to={item.webUrl}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <p style={{ fontWeight: "bold" }}>{`${
                            item.position || ""
                          }`}</p>{" "}
                          <p
                            style={{ fontStyle: "italic", opacity: 0.7 }}
                          >{`${moment(
                            item.timeCreated
                              ? new Date(item.timeCreated.seconds * 1000)
                              : new Date()
                          ).format("M/D")}`}</p>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <p>{item.name}</p>
                          <MdChevronRight
                            style={{ color: colors.accent, fontSize: 20 }}
                          />
                        </div>
                      </Link>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: -6,
                          marginTop: -3,
                        }}
                      >
                        <UnderlineButton
                          text="edit"
                          onClick={() => {
                            setEditSavedSearch(item);
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <div
                      key={index}
                      style={{
                        padding: 8,
                        backgroundColor: colors.white,
                        color: colors.accent,
                        borderRadius: 5,
                        marginBottom: 5,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                        onClick={() => {
                          props.sendUrl(item.url);
                        }}
                      >
                        <p style={{ fontWeight: "bold" }}>{`${
                          item.position || ""
                        }`}</p>{" "}
                        <p
                          style={{ fontStyle: "italic", opacity: 0.7 }}
                        >{`${moment(
                          item.timeCreated
                            ? new Date(item.timeCreated.seconds * 1000)
                            : new Date()
                        ).format("M/D")}`}</p>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        onClick={() => props.sendUrl(item.url)}
                      >
                        <p>{item.name}</p>
                        <MdChevronRight
                          style={{ color: colors.accent, fontSize: 20 }}
                        />
                      </div>
                    </div>
                  )
                ) : (
                  <div />
                );
              })}
            </div>
          ) : (
            <div />
          )}
        </div>
      )}
      <EditSavedSearchModal
        show={editSavedSearch !== null}
        savedSearch={editSavedSearch}
        onHide={() => setEditSavedSearch(null)}
        title={"Edit Saved Search"}
      />
    </div>
  );
}

export default AdvancedSearch;
