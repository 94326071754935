import React, { ReactNode, useEffect, useState } from "react";
import { useImmer } from "use-immer";
import PortalResult from "./PortalResult";
import { Switch as MUISwitch, Slider } from "@material-ui/core";
import ConferenceDropwdown from "dropdowns/conference-dropdown";
import { useFirestore, useFirestoreConnect } from "react-redux-firebase";
import InstitutionDropdown from "dropdowns/Institution-dropdown";
import EditSavedSearchModal from "modals/edit-saved-search-modal";
import { Button } from "react-bootstrap";
import { AiFillCaretDown, AiFillCaretRight } from "react-icons/ai";
import colors from "constants/colors";
import { auth } from "store/store";
import { useSelector } from "react-redux";
import moment from "moment";
import UnderlineButton from "shared/underline-button";
import RangeSelector from "../range-selector";
import { MAXES, MINS, STEPS } from "constants/search";

/*****************************************************************************
 * Types
 *****************************************************************************/

type TransferStatus = (typeof TRANSFER_STATUS)[number];
type Division = (typeof DIVISIONS)[number];
type FilterId = keyof InitialFilters;

export type InitialFilters = {
  institution: string;
  graduate: boolean;
  mtr: boolean;
  transferStatus: TransferStatus[];
  conference: string;
  division: Division[];
  w?: any; // wins
  l?: any; // losses
  wp?: any; // wild-pitches
  era?: any; // earned-run-average
  ra?: any; //run-average
  gs?: any; // games-started
  gf?: any; // games-finished
  cg?: any; // complete-game
  sho?: any; // shutouts
  sv?: any; // saves
  ip?: any; // innings-pitched
  h?: any; // hits-allowed
  r?: any; // runs-allowed
  er?: any; // earned-runs-allowed
  hr?: any; // home-runs-per-nine
  g?: any; // games-played
  bb?: any; // bases-on-balls
  ibb?: any; // intentional-bases-on-balls
  wlpct?: any; // win-loss-percentage
  yearstats?: any; // year-stats
};

export const stats = [
  "YEARSTATS",
  "W",
  "L",
  "ERA",
  "GS",
  "CG",
  "SHO",
  "SV",
  "IP",
  "H",
  "R",
  "ER",
  "HR",
  "BB",
  "WP",
  "RA",
  "IBB",
  "WLPCT",

  // "G",
  // "GF",
];

/*****************************************************************************
 * Constants
 *****************************************************************************/

const TRANSFER_STATUS = ["INC", "WTH", "MAT"];
const DIVISIONS = [1, 2, 3];

const initialFilters: InitialFilters = {
  institution: "",
  graduate: false,
  mtr: false,
  transferStatus: [],
  conference: "",
  division: [],
  yearstats: {
    min: MINS[stats[0]],
    max: MAXES[stats[0]],
    active: false,
  },
  w: {
    min: MINS[stats[1]],
    max: MAXES[stats[1]],
    active: false,
  },
  l: {
    min: MINS[stats[2]],
    max: MAXES[stats[2]],
    active: false,
  },
  era: {
    min: MINS[stats[3]],
    max: MAXES[stats[3]],
    active: false,
  },
  gs: {
    min: MINS[stats[4]],
    max: MAXES[stats[4]],
    active: false,
  },
  cg: {
    min: MINS[stats[5]],
    max: MAXES[stats[5]],
    active: false,
  },
  sho: {
    min: MINS[stats[6]],
    max: MAXES[stats[6]],
    active: false,
  },
  sv: {
    min: MINS[stats[7]],
    max: MAXES[stats[7]],
    active: false,
  },
  ip: {
    min: MINS[stats[8]],
    max: MAXES[stats[8]],
    active: false,
  },
  h: {
    min: MINS[stats[9]],
    max: MAXES[stats[9]],
    active: false,
  },
  r: {
    min: MINS[stats[10]],
    max: MAXES[stats[10]],
    active: false,
  },
  er: {
    min: MINS[stats[11]],
    max: MAXES[stats[11]],
    active: false,
  },
  hr: {
    min: MINS[stats[12]],
    max: MAXES[stats[12]],
    active: false,
  },
  bb: {
    min: MINS[stats[13]],
    max: MAXES[stats[13]],
    active: false,
  },
  wp: {
    min: MINS[stats[14]],
    max: MAXES[stats[14]],
    active: false,
  },
  ra: {
    min: MINS[stats[15]],
    max: MAXES[stats[15]],
    active: false,
  },
  ibb: {
    min: MINS[stats[16]],
    max: MAXES[stats[16]],
    active: false,
  },
  wlpct: {
    min: MINS[stats[17]],
    max: MAXES[stats[17]],
    active: false,
  },
};

/*****************************************************************************
 * AdvancedSearchPortal
 *****************************************************************************/

export default function AdvancedSearchPortal({
  setShowAdvancedOptions,
  setShowFilterIcon,
  showAll,
  handleShowAll,
  isNCAA,
  showAdvancedOptions,
}: {
  setShowAdvancedOptions: (value: boolean) => void;
  setShowFilterIcon: (value: boolean) => void;
  showAll: boolean;
  handleShowAll: () => void;
  isNCAA: boolean;
  showAdvancedOptions: boolean;
}) {
  const [filters, updateFilters] = useImmer(initialFilters);
  const [selectedTabCount, setSelectedTabCount] = useState(0);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [selectedTabItems, setSelectedTabItems] = useState<
    Record<FilterId, any>
  >({
    institution: 0,
    graduate: 0,
    mtr: 0,
    transferStatus: 0,
    conference: 0,
    division: 0,
    w: 0,
    l: 0,
    wp: 0,
    era: 0,
    ra: 0,
    gs: 0,
    gf: 0,
    cg: 0,
    sho: 0,
    sv: 0,
    ip: 0,
    h: 0,
    r: 0,
    er: 0,
    hr: 0,
    g: 0,
    bb: 0,
    ibb: 0,
    wlpct: 0,
    yearstats: 0,
  });
  const firestore = useFirestore();
  const [institutions, setInstitutions] = useState<any[]>([]);
  const [editSavedSearch, setEditSavedSearch] = React.useState(null);
  const [showSaved, setShowSaved] = useState(false);
  const [selectedTabs, setSelectedTabs] = useState<Set<FilterId>>(new Set());
  const mySearches = useSelector(
    (state: any) => state.firestore.data.mySearches
  );

  const getInstitutions = async () => {
    try {
      const institutionsSnapshot = await firestore
        .collection("institutions")
        .get();
      const institutions = institutionsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      return institutions;
    } catch (error) {
      console.error("Error fetching institutions: ", error);
      return [];
    }
  };

  useEffect(() => {
    getInstitutions().then((institutions) => {
      setInstitutions(institutions);
    });
  }, []);

  function resetAllFilters() {
    setFiltersApplied(false);
    updateFilters(() => initialFilters);
    setSelectedTabCount(0);
    setSelectedTabs(new Set());

    const newUrl = new URL(window.location.href);
    Object.keys(initialFilters).forEach((key) => {
      newUrl.searchParams.delete(key);
    });
    window.history.pushState({}, "", newUrl);
  }

  const handleApplyFilters = async () => {
    try {
      setShowFilterIcon(true);
      setShowAdvancedOptions(false);
      setFiltersApplied(true);

      const newUrl = new URL(window.location.href);

      Object.keys(initialFilters).forEach((key) => {
        newUrl.searchParams.delete(key);
      });

      Object.entries(filters).forEach(([key, value]) => {
        if (Array.isArray(value) && value.length > 0) {
          newUrl.searchParams.set(key, value.join(","));
        } else if (typeof value === "boolean" && value) {
          newUrl.searchParams.set(key, "true");
        } else if (value && typeof value === "string" && value.length > 0) {
          newUrl.searchParams.set(key, value);
        } else if (typeof value === "number") {
          newUrl.searchParams.set(key, value.toString());
        } else if (typeof value === "object" && value !== null) {
          const { min, max, active } = value;
          const initialRange = initialFilters[key];
          if (
            initialRange &&
            active &&
            (min !== initialRange.min || max !== initialRange.max)
          ) {
            newUrl.searchParams.set(`${key}_min`, min.toString());
            newUrl.searchParams.set(`${key}_max`, max.toString());
          }
        }
      });

      window.history.pushState({}, "", newUrl);
      handleShowAll();
    } catch (error) {
      console.error("Error applying filters:", error);
    }
  };

  useEffect(() => {
    setFiltersApplied(false);
  }, []);

  const handleTabSelection = (tabId: FilterId, wasSelected: boolean) => {
    setSelectedTabItems((prevSelectedItems) => {
      const updatedSelectedItems = { ...prevSelectedItems };

      updatedSelectedItems[tabId] = wasSelected
        ? Math.max(updatedSelectedItems[tabId] - 1, 0)
        : updatedSelectedItems[tabId] + 1;

      setSelectedTabs((prevSelectedTabs) => {
        const newSelectedTabs = new Set(prevSelectedTabs);
        if (updatedSelectedItems[tabId] > 0) {
          newSelectedTabs.add(tabId);
        } else {
          newSelectedTabs.delete(tabId);
        }
        setSelectedTabCount(newSelectedTabs.size);
        return newSelectedTabs;
      });

      return updatedSelectedItems;
    });
  };

  const userId = auth.currentUser?.uid;

  useFirestoreConnect([
    {
      collection: "users",
      doc: userId,
      subcollections: [{ collection: "searches" }],
      storeAs: `mySearches`,
    },
  ]);

  const filteredSavedSearches = () => {
    let saved = Object.keys(mySearches || {})
      .map((key) => (mySearches ? { ...mySearches[key], id: key } : null))
      .filter((item) => item !== null);
    if (isNCAA) {
      saved = saved.filter((item) => item.ncaaPortal);
    }

    return saved;
  };

  const renderListStats = () => {
    return stats.map((stat) => {
      return {
        id: stat.toLowerCase(),
        title: stat === "YEARSTATS" ? "Year" : stat,
        body: (
          <RangeSelector
            units={
              stat === "Weight"
                ? "lbs"
                : stat === "Height"
                ? "in"
                : stat === "Academic Class"
                ? "collegeClass"
                : ""
            }
            valmax={filters[stat.toLowerCase()].max || MAXES[stat]}
            valmin={filters[stat.toLowerCase()].min || MINS[stat]}
            setValues={(vals) => {
              updateFilters((draft) => {
                draft[stat.toLowerCase()] = {
                  min: vals[0],
                  max: vals[1],
                  active: true,
                };
              });
              handleTabSelection(stat.toLocaleLowerCase() as FilterId, false);
            }}
            min={MINS[stat]}
            max={MAXES[stat]}
            step={STEPS[stat]}
          />
        ),
      };
    });
  };

  const tabs = [
    {
      id: "institution",
      title: "Institution",
      body: (
        <InstitutionDropdown
          value={filters.institution}
          onSelect={(option: { id: string; institution: string } | null) => {
            updateFilters((draft) => {
              draft.institution = option?.institution || "";
            });
            handleTabSelection(
              "institution",
              option?.institution === initialFilters.institution
            );
          }}
          options={institutions || []}
          placeHolder="Institutions"
        />
      ),
    },
    {
      id: "graduate",
      title: "Graduate",
      body: (
        <MUISwitch
          checked={filters.graduate}
          onChange={() => {
            updateFilters((draft) => {
              draft.graduate = !filters.graduate;
            });
            handleTabSelection(
              "graduate",
              filters.graduate !== initialFilters.graduate
            );
          }}
        />
      ),
    },
    {
      id: "mtr",
      title: "MTR",
      body: (
        <MUISwitch
          checked={filters.mtr}
          onChange={() => {
            updateFilters((draft) => {
              draft.mtr = !filters.mtr;
            });
            handleTabSelection("mtr", filters.mtr !== initialFilters.mtr);
          }}
        />
      ),
    },
    {
      id: "transferStatus",
      title: "Transfer Status",
      body: (
        <ListSelector
          id="transferStatus"
          list={TRANSFER_STATUS}
          filters={filters}
          updateFilters={updateFilters}
          onTabSelect={(wasSelected) =>
            handleTabSelection("transferStatus", wasSelected)
          }
        />
      ),
    },
    {
      id: "conference",
      title: "Conference",
      body: (
        <ConferenceDropwdown
          selectItem={(item) => {
            const selectedConference = item[0] || "";
            updateFilters((draft) => {
              draft.conference = selectedConference;
            });
            handleTabSelection(
              "conference",
              selectedConference === initialFilters.conference
            );
          }}
          allowAll={true}
        />
      ),
    },
    {
      id: "division",
      title: "Division",
      body: (
        <ListSelector
          id="division"
          list={DIVISIONS}
          filters={filters}
          updateFilters={updateFilters}
          onTabSelect={(wasSelected) =>
            handleTabSelection("division", wasSelected)
          }
        />
      ),
    },
    ...renderListStats(),
  ] as { id: FilterId; title: string; body: ReactNode }[];

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const filtersFromUrl = {};

    // Parse URL parameters into filters object
    params.forEach((value, key) => {
      if (
        [
          "year",
          "conference",
          "division",
          "institution",
          "graduate",
          "mtr",
          "transferStatus",
        ].includes(key)
      ) {
        if (key === "division") {
          filtersFromUrl[key] = value.split(",");
        } else if (key === "transferStatus") {
          // Ensure transferStatus is always an array
          filtersFromUrl[key] = value.includes(",")
            ? value.split(",")
            : [value];
        } else if (["graduate", "mtr"].includes(key)) {
          filtersFromUrl[key] = value === "true";
        } else if (key === "year") {
          filtersFromUrl[key] = parseInt(value);
        } else {
          filtersFromUrl[key] = value;
        }
      }
    });

    // Remove filterPortal parameter if exists
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.delete("filterPortal");

    // Only update filters if URL has parameters
    if (Object.keys(filtersFromUrl).length > 0) {
      updateFilters(() => ({
        ...initialFilters,
        ...filtersFromUrl,
      }));

      // Update selected tabs
      let tabCount = 0;
      const newSelectedTabs = new Set<FilterId>();
      const newSelectedTabItems = { ...selectedTabItems };

      Object.entries(filtersFromUrl).forEach(([key, value]) => {
        if (value) {
          if (Array.isArray(value) && value.length > 0) {
            newSelectedTabItems[key as FilterId] = value.length;
            newSelectedTabs.add(key as FilterId);
            tabCount++;
          } else if (typeof value === "boolean" && value) {
            newSelectedTabItems[key as FilterId] = 1;
            newSelectedTabs.add(key as FilterId);
            tabCount++;
          } else if (value) {
            newSelectedTabItems[key as FilterId] = 1;
            newSelectedTabs.add(key as FilterId);
            tabCount++;
          }
        }
      });

      setSelectedTabItems(newSelectedTabItems);
      setSelectedTabs(newSelectedTabs);
      setSelectedTabCount(tabCount);
      setFiltersApplied(true);
      setShowFilterIcon(true);
    }
  }, []);

  return (
    <>
      {!showAll ? (
        <div>
          <div className="tabContainer">
            {tabs.map(({ id, title, body }) => (
              <div key={id} className="tabContent">
                <h4 style={{ margin: "10px 0 10px 0", color: "white" }}>
                  {title}
                </h4>
                {body}
              </div>
            ))}
          </div>
          <div style={{ marginTop: "20px" }}>
            <button
              style={{
                backgroundColor: "white",
                color: "#003366",
                padding: 4,
                border: "2px solid white",
                width: "20%",
                borderRadius: 8,
                cursor: "pointer",
              }}
              onClick={resetAllFilters}
            >
              Reset Filters
            </button>
            {selectedTabCount > 0 && (
              <button
                style={{
                  backgroundColor: "white",
                  color: "#003366",
                  padding: 4,
                  border: "2px solid white",
                  width: "20%",
                  borderRadius: 8,
                  cursor: "pointer",
                  marginLeft: 20,
                }}
                onClick={handleApplyFilters}
              >
                Apply Filters ({selectedTabCount})
              </button>
            )}
          </div>
        </div>
      ) : (
        <PortalResult
          showWithoutFilter={true}
          limit={true}
          filters={filters}
          setShowFilterIcon={setShowFilterIcon}
        />
      )}
      {isNCAA && showAdvancedOptions && (
        <div
          style={{
            width: "100%",
            marginLeft: "5%",
            height: 1,
            backgroundColor: "#fff",
            marginTop: 40,
            marginBottom: 25,
          }}
        />
      )}
      {isNCAA && showAdvancedOptions && (
        <div>
          <div
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyItems: "center",
              paddingLeft: 20,
              paddingRight: 20,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <h4
                style={{ fontWeight: 300, fontSize: 20, color: colors.white }}
              >
                MY SAVED SEARCHES
              </h4>
              <Button
                variant="secondary"
                style={{
                  marginTop: -12,
                  color: "white",
                  fontSize: 30,
                  backgroundColor: "transparent",
                  padding: 6,
                  borderWidth: 0,
                  boxShadow: "none",
                }}
                onClick={() => setShowSaved(!showSaved)}
              >
                {showSaved ? <AiFillCaretDown /> : <AiFillCaretRight />}
              </Button>
            </div>
          </div>
          {showSaved && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              {filteredSavedSearches().map((item, index) =>
                item && item.id && item.name ? (
                  <div
                    key={index}
                    style={{
                      padding: 8,
                      backgroundColor: colors.white,
                      color: colors.accent,
                      borderRadius: 5,
                      marginBottom: 5,
                      display: "flex",
                      flexDirection: "column",
                      position: "relative",
                      width: "40%",
                    }}
                    onClick={() => {
                      updateFilters(() => item.query);
                      const newSelectedTabItems: Record<FilterId, any> = {
                        conference: 0,
                        division: 0,
                        institution: 0,
                        graduate: 0,
                        mtr: 0,
                        transferStatus: 0,
                        w: 0,
                        l: 0,
                        wp: 0,
                        era: 0,
                        ra: 0,
                        gs: 0,
                        gf: 0,
                        cg: 0,
                        sho: 0,
                        sv: 0,
                        ip: 0,
                        h: 0,
                        r: 0,
                        er: 0,
                        hr: 0,
                        g: 0,
                        bb: 0,
                        ibb: 0,
                        wlpct: 0,
                        yearstats: 0,
                      };
                      let tabCount = 0;
                      Object.entries(initialFilters).forEach(
                        ([key, initialValue]) => {
                          const savedValue = item.query[key];
                          const isDifferent = Array.isArray(initialValue)
                            ? JSON.stringify(initialValue) !==
                            JSON.stringify(savedValue)
                            : initialValue !== savedValue;
                          if (isDifferent) {
                            newSelectedTabItems[key as FilterId] =
                              Array.isArray(savedValue)
                                ? savedValue.length
                                : savedValue
                                  ? 1
                                  : 0;
                            tabCount++;
                          }
                        }
                      );
                      setSelectedTabItems(newSelectedTabItems);
                      setSelectedTabCount(tabCount);
                      setFiltersApplied(tabCount > 0);
                    }}
                  >
                    {/* Display Item Name and Date */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {/* Name of the item */}
                      <p style={{ fontWeight: "bold" }}>{item.name}</p>
                      {/* Creation Date */}
                      <p style={{ fontStyle: "italic", opacity: 0.7 }}>
                        {moment(
                          item.timeCreated
                            ? new Date(item.timeCreated.seconds * 1000)
                            : new Date()
                        ).format("M/D")}
                      </p>
                    </div>

                    <div
                      style={{
                        flexDirection: "row",
                        justifyContent: "end",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <UnderlineButton
                        color={colors.accent}
                        text={"edit"}
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditSavedSearch(item);
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div key={index} />
                )
              )}
            </div>
          )}
        </div>
      )}
      <EditSavedSearchModal
        show={editSavedSearch !== null}
        savedSearch={editSavedSearch}
        onHide={() => setEditSavedSearch(null)}
        title={"Rename NCAA Saved Search"}
        ncaaPortal={true}
      />
    </>
  );
}

/*****************************************************************************
 * Helper Components
 *****************************************************************************/
function ListSelector({ id, list, filters, updateFilters, onTabSelect }) {
  const handleOptionSelect = (item) => {
    updateFilters((draft) => {
      const filterList = draft[id];
      const newFilterList = filterList.includes(item)
        ? filterList.filter((i) => i !== item)
        : [...filterList, item];
      draft[id] = newFilterList;
    });
    onTabSelect(filters[id].includes(item));
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {list.map((item, index) => (
        <button
          key={index}
          onClick={() => handleOptionSelect(item)}
          style={{
            backgroundColor: filters[id].includes(item)
              ? "white"
              : "transparent",
            color: filters[id].includes(item) ? "#003366" : "#fff",
            padding: 4,
            border: filters[id].includes(item)
              ? "2px solid transparent"
              : "2px solid white",
            width: "30%",
            borderRadius: 8,
            marginBottom: 8,
          }}
        >
          {item}
        </button>
      ))}
    </div>
  );
}
