import { useMe } from "../hooks/users";
import colors from "constants/colors";
import SourceDropdown from "dropdowns/source-dropdown";
// import { User } from "models/User";
import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useFirestore } from "react-redux-firebase";
import { toast } from "react-toastify";
import { updateDiscovery } from "services/MonitorService";
import { trackModalOpen, trackModalClose } from "../hooks/usePageTracking";

export default function EditDiscoveryModal({
  sources,
  show,
  onHide,
  stoplightId,
  discovery,
}: {
  stoplightId: string;
  onHide: any;
  show: any;
  sources: {
    active: boolean;

    guid: string;
    name: string;
  }[];
  discovery: any;
}) {
  const [selectedSources, setSelectedSources] = useState([]);
  const me = useMe();
  const firestore = useFirestore();
  const [term, setTerm] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (show && (term == "" || selectedSources.length == 0)) {
      setTerm(discovery.term);
      setSelectedSources(
        sources.filter((item) => discovery.sources.includes(item.guid))
      );
    }

    if (show) {
      trackModalOpen("Edit Discovery Modal");
    }
    return () => {
      if (show) {
        trackModalClose();
      }
    };
  }, [show]);

  const save = async () => {
    setLoading(true);
    let res = await updateDiscovery(
      stoplightId,
      term,
      selectedSources.map((item) => item.guid),
      discovery.collection_active,
      discovery.guid
    );
    await firestore
      .collection("users")
      .doc(me.id as unknown as string)
      .collection("discoveries")
      .doc(discovery.id)
      .update(res);
    setLoading(false);
    toast.dark(`Discovery updated!`, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
    });
    setTerm("");
    setSelectedSources([]);
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        setTerm("");
        setSelectedSources([]);
        onHide();
      }}
      //@ts-ignore
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        style={{
          color: colors.white,
          backgroundColor: colors.accent,
          borderBottomWidth: 0,
        }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          Edit Discovery
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          color: colors.white,
          backgroundColor: colors.accent,
          display: "flex",
          flexDirection: "column",
          paddingTop: 0,
          marginTop: 0,
        }}
        className="searching"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {sources.length > 0 ? (
            <div style={{ color: colors.darkGray }}>
              <SourceDropdown
                sources={sources}
                selected={selectedSources}
                selectSource={(res) => {
                  setSelectedSources(res);
                }}
              />
            </div>
          ) : (
            <div />
          )}

          <input
            type="text"
            style={{
              marginBottom: 10,
              marginTop: 20,
              display: "flex",
              flex: 1,
              border: "0px solid",
              color: "#fff",
              width: 340,
              backgroundColor: "transparent",
              borderBottom: "2px solid #fff",
            }}
            value={term}
            placeholder="Search Term"
            onChange={(evt) => setTerm(evt.target.value)}
          />

          {loading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: 4,
              }}
            >
              <Spinner animation="border" />
            </div>
          ) : (
            <div style={{ width: 5, height: 15 }} />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            setTerm("");
            setSelectedSources([]);
            onHide();
          }}
          style={{
            borderColor: colors.accent,
            backgroundColor: colors.white,
            color: colors.accent,
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            save();
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
