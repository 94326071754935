import { useMe } from "../hooks/users";
import colors from "constants/colors";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/storage";
// import { User } from "models/User";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useFirestore } from "react-redux-firebase";
import { toast } from "react-toastify";
import UnderlineButton from "shared/underline-button";
import { trackModalOpen, trackModalClose } from "../hooks/usePageTracking";

function MondayCredentialsModal(props: { onHide: any; show: any }) {
  const me = useMe();
  const firestore = useFirestore();
  const [workgroup, setWorkgroup] = useState("");
  const [apikey, setApikey] = useState("");
  const [boardName, setBoardName] = useState("");

  useEffect(() => {
    if (props.show) {
      trackModalOpen("Monday Credentials Modal");
    }
    return () => {
      if (props.show) {
        trackModalClose();
      }
    };
  }, [props.show]);

  useEffect(() => {
    if (me.credentials && me.credentials.monday) {
      setBoardName(me.credentials.monday.boardName);
      setWorkgroup(me.credentials.monday.workgroup);
      setApikey(me.credentials.monday.apikey);
    }
  }, []);

  const clearCreds = () => {
    setWorkgroup("");
    setApikey("");
    let credentials = {
      ...me.credentials,
      monday: null,
    };

    const docRefConfig = {
      collection: "users",
      doc: me.id as unknown as string,
    };

    firestore.update(docRefConfig, { credentials: credentials });
    props.onHide();
  };

  const save = () => {
    let credentials = {
      ...me.credentials,
      monday: {
        apikey: apikey,
        workgroup: workgroup,
        boardName: boardName,
      },
    };

    const docRefConfig = {
      collection: "users",
      doc: me.id as unknown as string,
    };

    firestore.update(docRefConfig, { credentials: credentials });

    let callable = firebase.functions().httpsCallable("checkMondayCredentials");

    try {
      callable({ userId: me.id }).then((res) => {
        console.log("callable response", res);

        toast.dark(`Credentials added.`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
        });

        props.onHide();
      });
    } catch (err) {
      console.log("err", err);

      props.onHide();
    }
  };

  return (
    <Modal
      {...props}
      //@ts-ignore
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        style={{
          color: colors.white,
          backgroundColor: colors.accent,
          borderBottomWidth: 0,
        }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          Monday.com Credentials
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          color: colors.white,
          backgroundColor: colors.accent,
          display: "flex",
          flexDirection: "column",
        }}
        className="searching"
      >
        <input
          type="text"
          style={{
            marginTop: -10,
            marginBottom: 10,
            display: "flex",
            flex: 1,
            border: "0px solid",
            color: "#fff",
            backgroundColor: "transparent",
            borderBottom: "2px solid #fff",
          }}
          value={apikey}
          placeholder="API Key"
          onChange={(evt) => setApikey(evt.target.value)}
        />

        <input
          type="text"
          style={{
            marginTop: 20,
            marginBottom: 10,
            display: "flex",
            flex: 1,
            border: "0px solid",
            color: "#fff",
            backgroundColor: "transparent",
            borderBottom: "2px solid #fff",
          }}
          value={workgroup}
          placeholder="Targeted Workgroup"
          onChange={(evt) => setWorkgroup(evt.target.value)}
        />

        <input
          type="text"
          style={{
            marginTop: 20,
            marginBottom: 10,
            display: "flex",
            flex: 1,
            border: "0px solid",
            color: "#fff",
            backgroundColor: "transparent",
            borderBottom: "2px solid #fff",
          }}
          value={boardName}
          placeholder="Targeted Board Name"
          onChange={(evt) => setBoardName(evt.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        {me && me.credentials && me.credentials.pg && (
          <div style={{ flex: 1, justifyContent: "flex-end" }}>
            <UnderlineButton text="Clear Credentials" onClick={clearCreds} />
          </div>
        )}

        <Button
          onClick={props.onHide}
          style={{
            borderColor: colors.accent,
            backgroundColor: colors.white,
            color: colors.accent,
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            save();
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MondayCredentialsModal;
