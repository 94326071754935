import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import LogRocket from "logrocket";

interface TrackingEvent {
  type: "page" | "modal";
  name: string;
  startTime: number;
}

const trackingStack: TrackingEvent[] = [];

export const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    const pageEvent: TrackingEvent = {
      type: "page",
      name: getPageName(location.pathname, location.search),
      startTime: Date.now(),
    };

    trackingStack.push(pageEvent);

    return () => {
      const endTime = Date.now();
      const event = trackingStack.pop();

      if (event) {
        const timeSpent = endTime - event.startTime;

        LogRocket.track(
          `Time Spent on ${event.type === "page" ? "" : "Modal: "}${
            event.name
          }`,
          {
            type: event.type,
            path: location.pathname,
            duration: `${Math.round(timeSpent / 1000)} seconds`,
          }
        );
      }
    };
  }, [location]);
};

export const trackModalOpen = (modalName: string) => {
  const modalEvent: TrackingEvent = {
    type: "modal",
    name: modalName,
    startTime: Date.now(),
  };
  trackingStack.push(modalEvent);
};

export const trackModalClose = () => {
  const endTime = Date.now();
  const event = trackingStack.pop();

  if (event && event.type === "modal") {
    const timeSpent = endTime - event.startTime;

    LogRocket.track(`Time Spent on Modal: ${event.name}`, {
      type: "modal",
      name: event.name,
      duration: `${Math.round(timeSpent / 1000)} seconds`,
    });
  }
};

const getPageName = (path: string, search: string) => {
  const segments = path.substring(1).split("/");

  if (segments[0] === "") return "Home";

  if (segments[0] === "search-results") {
    let pageName = "Advanced Search";

    const params = new URLSearchParams(search);

    if (params.get("asCollege") === "true") {
      pageName += " College";
    } else if (params.get("ncaaPortal") === "true") {
      pageName += " NCAA Portal";
    }

    return pageName;
  }

  return segments[0]
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
