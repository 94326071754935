import React, { useEffect, useState } from "react";
import AdvancedSearchPortal from "./AdvancedSearchPortal";
import PortalResults from "./PortalResult";

const PortalSearch = ({ isNCAA, setIsNCAA }) => {
  const [showAll, setShowAll] = useState(false);
  const [showFilterIcon, setShowFilterIcon] = useState(false);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(true);
  const [showAllAndAdvanced, setShowAllAndAdvanced] = useState(false);
  const [showFilterPortal, setShowFilterPortal] = useState(false);

  const pathname = window.location.pathname;
  if (pathname === "/search-results?ncaaPortal=true") {
    setIsNCAA(true);
  }

  const handleShowAll = () => {
    setShowAllAndAdvanced(!showAllAndAdvanced);
    setShowAll(!showAll);
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set("showAll", (!showAll).toString());
    window.history.pushState({}, "", newUrl);
  };

  const handleShowFilterPortal = () => {
    setShowAllAndAdvanced(!showAllAndAdvanced);
    setShowFilterPortal(!showFilterPortal);
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set("filterPortal", (!showFilterPortal).toString());
    window.history.pushState({}, "", newUrl);
  };

  const [isNcaaPortalUrl, setIsNcaaPortalUrl] = useState(false);
  const url = window.location.href;

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const showAllParam = params.get("showAll") === "true";
    const filterPortalParam = params.get("filterPortal") === "true";

    setShowAll(showAllParam);
    setShowFilterPortal(filterPortalParam);
    setShowAllAndAdvanced(showAllParam || filterPortalParam);

    if (url.includes("search-results?ncaaPortal=true")) {
      setIsNcaaPortalUrl(true);
    } else {
      setIsNcaaPortalUrl(false);
      setIsNCAA(false);
    }
  }, [setIsNCAA, url]);

  if (!isNcaaPortalUrl) {
    return null;
  }

  return (
    <div
      style={{
        paddingBottom: 80,
        width: showAll || showFilterPortal ? "auto" : "1000px",
      }}
    >
      <div style={{ paddingBottom: 25 }}>
        <div style={{ marginTop: 25 }}></div>
        {!showAll ? (
          <>
            {!showFilterPortal && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ color: "white", fontWeight: "bold", fontSize: 26 }}>
                  Advanced Search
                </p>
                <button
                  style={{
                    backgroundColor: "transparent",
                    color: "#fff",
                    padding: 4,
                    border: "2px solid white",
                    width: "30%",
                    borderRadius: 8,
                    marginLeft: 20,
                  }}
                  onClick={() => handleShowAll()}
                >
                  Show All
                </button>
              </div>
            )}
            <AdvancedSearchPortal
              setShowAdvancedOptions={setShowAdvancedOptions}
              setShowFilterIcon={setShowFilterIcon}
              showAll={showFilterPortal}
              handleShowAll={handleShowFilterPortal}
              isNCAA={isNCAA}
              showAdvancedOptions={showAdvancedOptions}
            />
          </>
        ) : (
          <div>
            <PortalResults showWithoutFilter={false} />
          </div>
        )}
      </div>
    </div>
  );
};

export default PortalSearch;
