import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import base64 from "base-64";

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export const baseUrlElasticSearch =
  "https://822d72960b3143a2aa8946b58375a7ef.us-east-1.aws.found.io:9243/ncaa_portals/_search?";
export const elasticPassword = "sbiAbeKXR7WeGjL7YMmKXduB";
const base = `https://tara-proxy.herokuapp.com`;

const getParams = () => {
  const headers = new Headers({
    Authorization: `Basic ${base64.encode("elastic:sbiAbeKXR7WeGjL7YMmKXduB")}`,
    "Content-Type": "application/json",
  });

  return {
    method: "POST",
    headers: headers,
  };
};

export const getAhowAllQuery = async (
  pageSize: number,
  from: number = 0,
  sort?: string,
  sortField?: string
) => {
  try {
    const finalSort = sort || "desc";
    const finalSortField = sortField || "initiated_date";
    const url = `${baseUrlElasticSearch}size=${pageSize}&from=${from}&sort=${finalSortField}:${finalSort}`;

    const res = await fetch(`${base}/${url}`, getParams());
    const data = await res.json();
    const ncaaPortals = data.hits.hits.map((item) => item._source);
    const total = data.hits.total.value;

    // Fetch matching Player Details for each NCAA Portal
    const playersWithDetails = await Promise.all(
      ncaaPortals.map(async (portal) => {
        try {
          const { first_name, last_name } = portal;
          const formattedFirstName = capitalizeFirstLetter(first_name || "");
          const formattedLastName = capitalizeFirstLetter(last_name || "");
          const sr_name = `${formattedFirstName} ${formattedLastName}`;

          if (!sr_name) {
            return { ...portal, playerDetails: null };
          }

          // Query the `sr_player_detail` collection for matching sr_name
          const playerDetailsSnapshot = await firebase
            .firestore()
            .collection("sr_player_detail")
            .where("sr_name", "==", sr_name)
            .limit(1)
            .get();

          const playerDetails = playerDetailsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          return { ...portal, playerDetails: playerDetails[0] || null };
        } catch {
          return { ...portal, playerDetails: null };
        }
      })
    );
    return { ncaaaRecords: playersWithDetails, total: total };
  } catch (error) {
    console.error("Error fetching data:", error);
    return { ncaaaRecords: [], total: 0 };
  }
};

const convertToQueryBody = (filters: any, pageSize: number) => {
  const must: any[] = [];
  const pitchingStatsMust: any[] = [];

  // Handle non-nested queries first

  if (filters.conference) {
    must.push({ match: { conference: filters.conference } });
  }

  if (filters.institution) {
    must.push({ match: { institution: filters.institution } });
  }

  if (typeof filters.graduate === "boolean") {
    must.push({ match: { is_graduate: filters.graduate ? "Y" : "N" } });
  }

  if (typeof filters.mtr === "boolean") {
    must.push({
      match: { multiple_transfer_portal_records: filters.mtr ? "Y" : "N" },
    });
  }

  if (Array.isArray(filters.division) && filters.division.length > 0) {
    must.push({
      bool: {
        should: filters.division.map((div) => ({
          match: {
            division: div,
          },
        })),
      },
    });
  }

  if (
    Array.isArray(filters.transferStatus) &&
    filters.transferStatus.length > 0
  ) {
    must.push({
      bool: {
        should: filters.transferStatus.map((status) => ({
          match: { transfer_status: status },
        })),
      },
    });
  }

  if (filters.yearstats?.active) {
    must.push({
      range: {
        year: {
          gte: filters.yearstats.min,
          lte: filters.yearstats.max,
        },
      },
    });
    pitchingStatsMust.push({
      range: {
        "pitching_stats.year": {
          gte: filters.yearstats.min,
          lte: filters.yearstats.max,
        },
      },
    });
  }

  // Handle pitching stats in nested query
  const statsMapping = {
    era: "earned-run-average",
    wp: "wild-pitches",
    ra: "run-average",
    wlpct: "win-loss-percentage",
    ip: "innings-pitched",
    h: "hits-allowed",
    r: "runs-allowed",
    er: "earned-runs-allowed",
    hr: "home-runs-per-nine",
    bb: "bases-on-balls",
    ibb: "IBB",
    cg: "complete-game",
    gs: "games-started",
    sv: "saves",
    w: "wins",
    l: "losses",
  };

  // Add active stat filters to pitching_stats nested query
  Object.entries(statsMapping).forEach(([key, fieldName]) => {
    if (filters[key]?.active) {
      const min = filters[key].min;
      const max = filters[key].max;

      if (min === 0) {
        pitchingStatsMust.push({
          bool: {
            should: [
              {
                bool: {
                  must_not: {
                    exists: { field: `pitching_stats.${fieldName}` },
                  },
                },
              },
              {
                range: {
                  [`pitching_stats.${fieldName}`]: {
                    gte: min,
                    lte: max,
                  },
                },
              },
            ],
          },
        });
      } else {
        pitchingStatsMust.push({
          range: {
            [`pitching_stats.${fieldName}`]: {
              gte: min,
              lte: max,
            },
          },
        });
      }
    }
  });

  // Add nested query only if there are pitching stats filters
  if (pitchingStatsMust.length > 0) {
    must.push({
      nested: {
        path: "pitching_stats",
        query: {
          bool: {
            must: pitchingStatsMust,
          },
        },
      },
    });
  }

  return {
    query: {
      bool: {
        must,
      },
    },
    size: pageSize,
  };
};

export const fetchFilterData = async (
  pageSize = 20,
  filters,
  from: number = 0,
  sort?: string,
  sortField?: string
) => {
  try {
    const finalSort = sort || "desc";
    const finalSortField = sortField || "initiated_date";

    console.log(finalSort, finalSortField, "sort, sortField");
    const queryBody = convertToQueryBody(filters, pageSize);

    const url = `${baseUrlElasticSearch}size=${pageSize}&from=${from}&sort=${finalSortField}:${finalSort}`;

    const res = await fetch(`${base}/${url}`, {
      ...getParams(),
      body: JSON.stringify(queryBody),
    });
    const data = await res.json();
    const ncaaPortals = data.hits.hits.map((item) => item._source);

    const playersWithDetails = await Promise.all(
      ncaaPortals.map(async (portal: any) => {
        try {
          const { first_name, last_name } = portal;
          const formattedFirstName = capitalizeFirstLetter(first_name || "");
          const formattedLastName = capitalizeFirstLetter(last_name || "");
          const sr_name = `${formattedFirstName} ${formattedLastName}`;

          if (!sr_name) {
            return { ...portal, playerDetails: null };
          }

          // queryData the `sr_player_detail` collection for matching sr_name
          const playerDetailsSnapshot = await firebase
            .firestore()
            .collection("sr_player_detail")
            .where("sr_name", "==", sr_name)
            .limit(1)
            .get();

          const playerDetails = playerDetailsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          return { ...portal, playerDetails: playerDetails[0] || null };
        } catch {
          return { ...portal, playerDetails: null };
        }
      })
    );
    return { ncaaaRecords: playersWithDetails, total: data.hits.total.value };
  } catch (error) {
    console.error("Error fetching paginated data:", error);
    return { ncaaaRecords: [], total: 0 };
  }
};

export const getTotalPortalsCount = async () => {
  try {
    const res = await fetch(`${base}/${baseUrlElasticSearch}`, getParams());
    const data = await res.json();

    return data.hits.total.value;
  } catch (error) {
    console.error("Error getting total count:", error);
    return 0;
  }
};

export const singlePlayerDetails = async (playerId: string) => {
  try {
    const url = `${baseUrlElasticSearch}q=external_id:${playerId}`;
    const res = await fetch(`${base}/${url}`, getParams());
    const data = await res.json();
    const ncaa_portals = data.hits.hits.map((item) => item._source);

    const playersWithDetails = await Promise.all(
      ncaa_portals.map(async (portal: any) => {
        try {
          const { first_name, last_name } = portal;
          const formattedFirstName = capitalizeFirstLetter(first_name || "");
          const formattedLastName = capitalizeFirstLetter(last_name || "");
          const sr_name = `${formattedFirstName} ${formattedLastName}`;

          if (!sr_name) {
            return { ...portal, playerDetails: null };
          }

          // Query the `sr_player_detail` collection for matching sr_name
          const playerDetailsSnapshot = await firebase
            .firestore()
            .collection("sr_player_detail")
            .where("sr_name", "==", sr_name)
            .limit(1)
            .get();

          const playerDetails = playerDetailsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          return { ...portal, playerDetails: playerDetails[0] || null };
        } catch {
          return { ...portal, playerDetails: null };
        }
      })
    );
    return playersWithDetails;
  } catch (error) {
    console.error("Error fetching paginated data:", error);
    return { data: [] };
  }
};

export const getLastUpdated = async () => {
  const snapshot = await firebase
    .firestore()
    .collection("syncHistory")
    .where("fileName", "==", "transfer_data.csv")
    .limit(1)
    .get();

  if (snapshot.empty) {
    return null;
  }

  const timestamp = snapshot.docs[0].data().date;

  // Convert to formatted date string
  if (timestamp) {
    return new Date(timestamp.seconds * 1000).toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  return null;
};
