import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./player-page.css";
import { compose } from "recompose";
import { withFirestore } from "react-redux-firebase";
import { Spinner } from "react-bootstrap";
import colors from "constants/colors";
import { SAFE_toFixed } from "services/utils";
import { singlePlayerDetails } from "pages/search-page/components/PortalSearch/ncaaPortal";

function NCAAPlayerPage(props: any) {
  let { id } = useParams();
  const [player, setPlayer] = useState<any | null>(null);

  const [fetching, setFetching] = useState(false);


  useEffect(() => {
    const fetchSinglePlayerData = async () => {
      if (!fetching) {
        setFetching(true);
        const playerDetails = await singlePlayerDetails(id);
        setPlayer(playerDetails[0]);
      }
    };
    fetchSinglePlayerData();
  }, [id]);

  const renderNameRow = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <h2 style={{ fontFamily: "Raleway", color: "#fff" }}>
          {player ? player?.playerDetails?.sr_name : ""}
        </h2>
      </div>
    );
  };

  const th = (name) => {
    return (
      <th
        style={{
          borderCollapse: "collapse",
          backgroundColor: colors.lightBlue,
          color: colors.white,
          textAlign: "center",
          padding: 4,
        }}
      >
        {name}
      </th>
    );
  };

  const td = (val) => {
    let stringVal = val;
    if (val && !isNaN(parseFloat(val)) && parseFloat(val) < 1) {
      stringVal = SAFE_toFixed(val, 3);
      stringVal = stringVal.replace(/^0+/, "");
    }

    return (
      <td
        style={{
          borderCollapse: "collapse",
          border: "1px solid #467DB3",
          color: colors.accent,
          textAlign: "center",
          padding: 4,
        }}
      >
        {stringVal}
      </td>
    );
  };

  const tdWithColor = (val, key) => {
    let stringVal = val;
    let strFirst = val?.split(".")[0] || "";

    if (
      key === "W" ||
      key === "L" ||
      key === "G" ||
      key === "GS" ||
      key === "GF" ||
      key === "CG" ||
      key === "SHO" ||
      key === "SV" ||
      key === "H" ||
      key === "R" ||
      key === "ER" ||
      key === "HR" ||
      key === "BB" ||
      key === "IBB"
    ) {
      if (parseInt(strFirst)) {
        const keyInt = parseInt(val);
        stringVal = keyInt.toString();
      }
    }

    if (key === "W-L%") {
      if (!isNaN(parseFloat(val))) {
        const parsedVal = parseFloat(val);

        stringVal =
          parsedVal === 0 ? "0" : parsedVal.toFixed(3).replace(/^0\./, ".");
      }
    }

    if (key === "ERA" || key === "RA") {
      stringVal = parseFloat(val).toFixed(2).replace(/^0\./, "0");
      if (stringVal.startsWith("0.")) {
        stringVal = "0";
      }
    }
    if (key === "IP") {
      stringVal = parseFloat(val).toFixed(1).replace(/^0\./, "0");
      if (stringVal.startsWith("0.")) {
        stringVal = "0";
      }
    }

    return (
      <td
        style={{
          borderCollapse: "collapse",
          border: "1px solid #467DB3",
          color: colors.accent,
          textAlign: "center",
          padding: 4,
          backgroundColor: colors.lightGray,
        }}
      >
        {stringVal}
      </td>
    );
  };

  const tdWithColorForBatting = (val, key) => {
    let stringVal = val;
    let strFirst = val?.split(".")[0] || "";

    if (
      key === "G" ||
      key === "PA" ||
      key === "AB" ||
      key === "R" ||
      key === "H" ||
      key === "2B" ||
      key === "3B" ||
      key === "HR" ||
      key === "RBI" ||
      key === "SB" ||
      key === "CS" ||
      key === "BB" ||
      key === "SO" ||
      key === "TB" ||
      key === "GDP" ||
      key === "HBP" ||
      key === "SH" ||
      key === "SF" ||
      key === "IBB"
    ) {
      if (parseInt(strFirst)) {
        const keyInt = parseInt(val);
        stringVal = keyInt.toString();
      }
    }

    if (key === "BA" || key === "OBP" || key === "SLG" || key === "OPS") {
      if (!isNaN(parseFloat(val))) {
        const parsedVal = parseFloat(val);
        stringVal =
          parsedVal === 0 ? "0" : parsedVal.toFixed(3).replace(/^0\./, ".");
      }
    }
 
    return (
      <td
        style={{
          borderCollapse: "collapse",
          border: "1px solid #467DB3",
          color: colors.accent,
          textAlign: "center",
          padding: 4,
          backgroundColor: colors.lightGray,
        }}
      >
        {stringVal}
      </td>
    );
  };

  const isPitcher = () => {
    const pitcher = player?.playerDetails?.sr_position
      .toLowerCase()
      .includes("pitcher");
    if (pitcher) {
      return true;
    } else {
      return false;
    }
  };

  const renderPitchingStats = (stats: any) => {
    return (
      <table style={{ width: "100%", border: "1px solid #467DB3" }}>
        <thead>
          <tr>
            {th("Year")}
            {th("TM")}
            {th("LG")}
            {th("W")}
            {th("L")}
            {th("W-L%")}
            {th("ERA")}
            {th("RA")}
            {th("GS")}
            {th("GF")}
            {th("CG")}
            {th("SHO")}
            {th("SV")}
            {th("IP")}
            {th("H")}
            {th("R")}
            {th("ER")}
            {th("HR")}
            {th("G")}
            {th("BB")}
            {th("IBB")}
          </tr>
        </thead>
        <tbody>
          {stats.map((stat) => {
            return (
              <tr style={{ backgroundColor: colors.lightGray }}>
                {td(stat.year.toString())}
                {td(stat.team)}
                {td(stat.league)}
                {tdWithColor(stat.wins?.toString(), "W")}
                {tdWithColor(stat.losses?.toString(), "L")}
                {tdWithColor(stat["win-loss-percentage"]?.toString(), "W-L%")}
                {tdWithColor(stat["earned-run-average"]?.toString(), "ERA")}
                {tdWithColor(stat["run-average"]?.toString(), "RA")}
                {tdWithColor(stat["games-started"]?.toString(), "GS")}
                {tdWithColor(stat["games-finished"]?.toString(), "GF")}
                {tdWithColor(stat["complete-game"]?.toString(), "CG")}
                {tdWithColor(stat.shutouts?.toString(), "SHO")}
                {tdWithColor(stat.saves?.toString(), "SV")}
                {tdWithColor(stat["innings-pitched"]?.toString(), "IP")}
                {tdWithColor(stat["hits-allowed"]?.toString(), "H")}
                {tdWithColor(stat["runs-allowed"]?.toString(), "R")}
                {tdWithColor(stat["earned-runs-allowed"]?.toString(), "ER")}
                {tdWithColor(stat["home-runs-per-nine"]?.toString(), "HR")}
                {tdWithColor(stat["games-played"]?.toString(), "G")}
                {tdWithColor(stat["bases-on-balls"]?.toString(), "BB")}
                {tdWithColor(
                  stat["intentional-bases-on-balls"]?.toString() === "none"
                    ? "0"
                    : stat["intentional-bases-on-balls"]?.toString() || "0",
                  "IBB"
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const renderBattingStats = (stats: any) => {
    return (
      <table style={{ width: "100%", border: "1px solid #467DB3" }}>
        <thead>
          <tr>
            {th("Year")}
            {th("TM")}
            {th("LG")}
            {th("G")}
            {th("PA")}
            {th("AB")}
            {th("R")}
            {th("H")}
            {th("2B")}
            {th("3B")}
            {th("HR")}
            {th("RBI")}
            {th("SB")}
            {th("CS")}
            {th("BB")}
            {th("SO")}
            {th("BA")}
            {th("OBP")}
            {th("SLG")}
            {th("OPS")}
            {th("TB")}
            {th("GDP")}
            {th("HBP")}
            {th("SH")}
            {th("SF")}
            {th("IBB")}
          </tr>
        </thead>
        <tbody>
          {Object.entries(stats).map(([year, dataObjects]: [string, any]) =>
            Object.entries(dataObjects).map(([team, data]: [string, any]) => (
              <>
                <tr key={year} style={{ backgroundColor: colors.lightGray }}>
                  {td(year)}
                  {td(team)}
                  {td(data.league)}
                  {tdWithColorForBatting(data.games, "G")}
                  {tdWithColorForBatting(data["plate-appearances"], "PA")}
                  {tdWithColorForBatting(data["at-bats"], "AB")}
                  {tdWithColorForBatting(data.runs, "R")}
                  {tdWithColorForBatting(data.hits, "H")}
                  {tdWithColorForBatting(data.doubles, "2B")}
                  {tdWithColorForBatting(data.triples, "3B")}
                  {tdWithColorForBatting(data["home-runs"], "HR")}
                  {tdWithColorForBatting(data.rbis, "RBI")}
                  {tdWithColorForBatting(data["stolen-bases"], "SB")}
                  {tdWithColorForBatting(data["caught-stealing"], "CS")}
                  {tdWithColorForBatting(data["bases-on-balls"], "BB")}
                  {tdWithColorForBatting(data.strikeouts, "SO")}
                  {tdWithColorForBatting(data["batting-avg"], "BA")}
                  {tdWithColorForBatting(data["on-base-percentage"], "OBP")}
                  {tdWithColorForBatting(data["slugging-percentage"], "SLG")}
                  {tdWithColorForBatting(data["on-base-plus-slugging"], "OPS")}
                  {tdWithColorForBatting(data["total_bases"], "TB")}
                  {tdWithColorForBatting(
                    data["double-plays-grounded-into"],
                    "GDP"
                  )}
                  {tdWithColorForBatting(data["hit-by-pitch"], "HBP")}
                  {tdWithColorForBatting(data["sacrifice-hits"], "SH")}
                  {tdWithColorForBatting(data["sacrifice-flys"], "SF")}
                  {tdWithColorForBatting(
                    data["IBB"] === "none" ? "0" : data["IBB"] || "0",
                    "IBB"
                  )}
                </tr>
              </>
            ))
          )}
        </tbody>
      </table>
    );
  };

  const renderNcaaDetails = () => {
    const isPlayerPitcher = isPitcher();

    const stats = isPlayerPitcher
      ? player.pitching_stats
      : player.playerDetails.sr_batting_stats;

    return isPlayerPitcher
      ? renderPitchingStats(stats)
      : renderBattingStats(stats);
  };

  const renderGeneralInfo = () => {
    return player ? (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="middle-text-container">
          <p className="middle-text-small">
            Bats:
            <p className="middle-text-bold">
              {player ? player.playerDetails?.sr_bats : "N/A"}
            </p>
          </p>
          <p className="middle-text-small">
            Position:
            <p className="middle-text-bold">
              {player ? player.playerDetails?.sr_position : "N/A"}
            </p>
          </p>
          <p className="middle-text-small">
            Height:
            <p className="middle-text-bold">
              {player && player.playerDetails?.sr_height
                ? player.playerDetails?.sr_height
                : "N/A"}
            </p>
          </p>
        </div>
        <div className="middle-text-container">
          <p className="middle-text-small">
            Weight:
            <p className="middle-text-bold">
              {player && player.playerDetails?.sr_weight
                ? player.playerDetails?.sr_weight
                : "N/A"}
            </p>
          </p>
          <p className="middle-text-small">
            Conference:
            <p className="middle-text-bold">
              {player && player?.conference !== null
                ? `${player?.conference}`
                : "N/A"}
            </p>
          </p>
        </div>
      </div>
    ) : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
        }}
      >
        <Spinner animation="border" />
      </div>
    );
  };

  const renderStats = () => {
    if (player) {
      if (player?.playerDetails) {
        return (
          <div
            style={{
              marginLeft: 15,
              marginTop: 10,
              flex: 1,

              width: "100%",
            }}
          >
            <p
              style={{
                fontSize: 24,
                color: colors.lightBlue,
                fontWeight: "bold",
              }}
            >
              {isPitcher() ? "PITCHING STATS" : "BATTING STATS"}
            </p>

            {renderNcaaDetails()}
            {player.Year ? (
              <p
                style={{
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontSize: 12,
                  textAlign: "right",
                }}
              >{`Stats collected in ${player.Year}`}</p>
            ) : (
              <div />
            )}
          </div>
        );
      } else
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
            }}
          >
            No addional information available
          </div>
        );
    }
    return <div />;
  };

  const renderPhoto = () => {
    return (
      <div
        style={{
          borderRadius: 9,
          flexDirection: "column",
          display: "flex",
          marginLeft: 15,
          marginTop: -85,
        }}
      >
        <div
          className="profile-picture"
          style={{
            marginTop: -40,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: colors.accent,
          }}
        >
          <p
            style={{
              color: colors.white,
              fontSize: 100,
              fontWeight: 800,
            }}
          >
            {player?.first_name[0] + player?.last_name[0]}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        marginLeft: "1rem",
        backgroundColor: "#fff",
        display: "flex",
        minHeight: "90vh",
        flexDirection: "column",
        borderColor: colors.lightBlue,
        borderWidth: 5,
        borderStyle: "solid",
      }}
    >
      <div
        style={{
          backgroundColor: colors.lightBlue,
          height: 130,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          paddingLeft: 275,
        }}
      >
        {renderNameRow()}
      </div>

      <div
        style={{
          flexDirection: "column",
          display: "flex",

          flex: 1,
        }}
      >
        <div
          style={{
            flexDirection: "row",
            display: "flex",

            flex: 1,
          }}
        >
          {renderPhoto()}
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              flex: 1,
              marginRight: 20,
            }}
          >
            {renderGeneralInfo()}
            {renderStats()}
          </div>
        </div>
      </div>
    </div>
  );
}

const enhance = compose(withFirestore);

export default enhance(NCAAPlayerPage);
