import firebase from "firebase/compat/app";
import { Player } from "../models/Player";
import { PositionReport } from "../models/PositionReport";

export class NotificationService {
  static markNotificationRead = async (
    notification: any,
    me: any,
    firestore: any
  ) => {
    const notificationRefConfig = {
      collection: "notifications",
      doc: notification.id,
    };

    await firestore.update(notificationRefConfig, {
      read: true,
    });
  };

  static markNotificationRemoved = async (
    notification: any,
    firestore: any
  ) => {
    const notificationRefConfig = {
      collection: "notifications",
      doc: notification.id,
    };

    await firestore.update(notificationRefConfig, {
      read: true,
      removed: true,
    });
  };

  static acceptFriendRequest = async (
    notification: any,
    me: any,
    firestore: any
  ) => {
    NotificationService.markNotificationRead(notification, me, firestore);
    const myId = me.id;
    const meRefConfig = {
      collection: "users",
      doc: myId,
    };
    const senderRefConfig = {
      collection: "users",
      doc: notification.sender,
    };

    await firestore.update(meRefConfig, {
      friends: firebase.firestore.FieldValue.arrayUnion(notification.sender),
    });

    await firestore.update(senderRefConfig, {
      friends: firebase.firestore.FieldValue.arrayUnion(myId),
    });
  };

  static addFriendPlaceholder = async (
    email: string,
    me: any,
    firestore: any
  ) => {
    let request = {
      removed: false,
      read: false,
      notified: false,
      sender: me.id.toString(),
      recipient: null,
      timeCreated: new Date(),
      senderName: `${me.firstName} ${me.lastName}`,
      recipientPushToken: "",
      text: `New friend request from ${me.firstName} ${me.lastName}`,
      kind: "friendRequest",
      category: "friends",
      recipientEmail: email,
    };

    const docRefConfig = {
      collection: "notifications",
    };
    await firestore.add(docRefConfig, request);
  };

  static addFriend = async (user: any, me: any, firestore: any) => {
    let request = {
      ...NotificationService.getBaseNotification(me, user),
      text: `New friend request from ${me.firstName} ${me.lastName}`,
      kind: "friendRequest",
      category: "friends",
      recipientEmail: user.email,
      recipientName: `${user.firstName} ${user.lastName}`,
    };

    const docRefConfig = {
      collection: "notifications",
    };
    await firestore.add(docRefConfig, request);

    await NotificationService.sendMailIfNeeded(
      user,
      me,
      firestore,
      //@ts-ignore
      request.kind
    );
  };

  static addShareImageToReport = async (
    report: PositionReport,
    me: any,
    shareURL: string,
    pdfURL: string,
    firestore: any
  ) => {
    const reportsDocRef = {
      collection: "users",
      doc: me.id,
      subcollections: [{ collection: "reports", doc: report.id }],
    };

    await firestore.update(reportsDocRef, {
      shareURL: shareURL,
      pdfURL: pdfURL,
    });
  };

  static sendProspect = async (
    player: Player,
    user: any,
    me: any,
    firestore: any
  ) => {
    let request = {
      ...NotificationService.getBaseNotification(me, user),
      text: `${me.firstName} ${me.lastName} shared a new prospect with you`,
      kind: "sharedProspect",
      category: "friends",
      prospect: player,
    };

    const docRefConfig = {
      collection: "notifications",
    };
    await firestore.add(docRefConfig, request);

    await NotificationService.sendMailIfNeeded(
      user,
      me,
      firestore,
      //@ts-ignore
      request.kind
    );
  };

  static sendReport = async (
    report: PositionReport,
    user: any,
    me: any,
    firestore: any
  ) => {
    let request = {
      ...NotificationService.getBaseNotification(me, user),
      text: `${me.firstName} ${me.lastName} shared a report with you`,
      kind: "sharedReport",
      category: "friends",
      report: report,
    };

    const docRefConfig = {
      collection: "notifications",
    };
    await firestore.add(docRefConfig, request);

    await NotificationService.sendMailIfNeeded(
      user,
      me,
      firestore,
      //@ts-ignore
      request.kind,
      report,
      true
    );
  };

  static revokeBoardAccess = async (
    recipientIds: string[],
    sender: any,
    sharedBoard: any,
    firestore: any
  ) => {
    const boardsDocRefConfig = {
      collection: "boards",
      doc: sharedBoard.id,
    };

    await firestore.update(boardsDocRefConfig, {
      recipientIds: firebase.firestore.FieldValue.arrayRemove(...recipientIds),
    });

    const meDocRefConfig = {
      collection: "users",
      doc: sender.id,
    };

    let currentSharedBoards = (sender.sharedBoards || []).filter(
      (item) => !recipientIds.includes(item)
    );
    await firestore.update(meDocRefConfig, {
      sharedBoards: currentSharedBoards,
    });
  };

  static sendBoard = async (
    user: any,
    me: any,
    board: {
      name: String;
      id: String;
      senderName: String;
      senderId: String;
      isSecondary: boolean;
    },
    firestore: any
  ) => {
    let sharedBoard = {
      senderId: me.id,
      senderName: `${me.firstName} ${me.lastName}`,
      name:
        board.name == "My Big Board"
          ? `${me.firstName} ${me.lastName}'s Board`
          : `${me.firstName} ${me.lastName}'s ${board.name}`,
      isSecondary: board.isSecondary,
      id: board.id,
    };

    let request = {
      ...NotificationService.getBaseNotification(me, user),
      text: `${me.firstName} ${me.lastName} shared a big board with you`,
      kind: "sharedBoard",
      category: "friends",
      object: sharedBoard,
    };

    const docRefConfig = {
      collection: "notifications",
    };
    await firestore.add(docRefConfig, request);

    const boardsDocRefConfig = {
      collection: "boards",
      doc: board.id,
    };

    await firestore.update(boardsDocRefConfig, {
      recipientIds: firebase.firestore.FieldValue.arrayUnion(user.id),
    });

    const meDocRefConfig = {
      collection: "users",
      doc: me.id,
    };

    let currentSharedBoards = me.sharedBoards || [];
    currentSharedBoards.push(user.id.toString());
    await firestore.update(meDocRefConfig, {
      sharedBoards: currentSharedBoards,
    });

    await NotificationService.sendMailIfNeeded(
      user,
      me,
      firestore,
      //@ts-ignore
      request.kind
    );
  };

  static sendScholarships = async (
    user: any,
    me: any,
    board: {
      name: String;
      senderId: String;
    },
    firestore: any
  ) => {
    let sharedScholarships = {
      senderId: me.id,
      name: `${me.firstName} ${me.lastName}`,
    };

    let request = {
      ...NotificationService.getBaseNotification(me, user),
      text: `${me.firstName} ${me.lastName} shared their scholarships overview with you`,
      kind: "sharedScholarships",
      category: "friends",
      object: sharedScholarships,
    };

    const docRefConfig = {
      collection: "notifications",
    };
    await firestore.add(docRefConfig, request);

    const scholarshipsRefConfig = {
      collection: "users",
      doc: user.id,
      subcollections: [{ collection: "sharedScholarships", doc: me.id }],
    };

    await firestore.set(scholarshipsRefConfig, sharedScholarships, {
      merge: true,
    });

    await NotificationService.sendMailIfNeeded(
      user,
      me,
      firestore,
      //@ts-ignore
      request.kind
    );
  };

  // HELPERS

  static getBaseNotification = (me: any, user: any): any => {
    return {
      removed: false,
      read: false,
      notified: user.notifyFriendRequests && user.notifyMessages ? false : true,
      sender: me.id.toString(),
      recipient: user.id.toString(),
      timeCreated: new Date(),
      senderName: `${me.firstName} ${me.lastName}`,
      recipientPushToken: user.pushToken ? user.pushToken : "",
    };
  };

  static sendMailIfNeeded = async (
    user: any,
    me: any,
    firestore: any,
    kind:
      | "sharedProspect"
      | "friendRequest"
      | "sharedBoard"
      | "sharedReport"
      | "playerMatch",
    report?: PositionReport,
    forceTrue?: boolean
  ) => {
    if (user.emailNotifications || forceTrue) {
      let subject = "";
      let text = "";

      switch (kind) {
        case "friendRequest":
          subject = `New friend request from ${me.firstName} ${me.lastName}`;
          text = "See the Scout App for more information.";
          break;
        case "sharedBoard":
          subject = `${me.firstName} ${me.lastName} shared their big board with you`;
          text = "See the Scout App for more information.";
          break;
        case "sharedReport":
          if (report) {
            subject = `${me.firstName} ${me.lastName} shared a report with you`;
            text = `<img src=${report.shareURL}></img>`;

            if (report.videos && report.videos.length > 0) {
              report.videos.forEach((vid, index) => {
                text = `${text}<p><a href="${vid}">Video Attachment ${
                  index + 1
                }</a></p>`;
              });
            }
            if (report.photos && report.photos.length > 0) {
              report.photos.forEach((vid, index) => {
                text = `${text}<p><a href="${vid}">Photo Attachment ${
                  index + 1
                }</a></p>`;
              });
            }
            text = `${text}<p>Download the .pdf Version <a href="${report.pdfURL}">here</a>.</p>`;
          }

          break;
        case "sharedProspect":
          text = "See the Scout App for more information.";
          subject = `${me.firstName} ${me.lastName} shared a new prospect with you`;
          break;
      }

      await firestore.add(
        { collection: "mail" },
        {
          to: user.email,
          message: {
            subject: subject,
            text: text,
            html: text,
          },
        }
      );
    }
  };
}
