/*****************************************************************************
 * Import
 *****************************************************************************/
import firebase from "firebase/compat/app";

import { BugReport } from "models/bug-report";

/*****************************************************************************
 * Public Functions
 *****************************************************************************/

export const submitBugReport = async (form: BugReport) => {
  const url = `https://us-central1-scout2-9bc3f.cloudfunctions.net/sendBugReportToTrello`;

  console.log(form);
  console.log(JSON.stringify(form));

  return await fetch(url, {
    method: "POST",
    /* mode: 'no-cors', */
    body: JSON.stringify(form),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => console.log(response));
};
