import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import ScoutTitle from "shared/scout-title";
import SaveSearchModal from "modals/save-search-modal";
import HelpModal from "modals/help-modal";
import ResultTableNCAA from "./result-item-ncaa";
import {
  fetchFilterData,
  getAhowAllQuery,
  getLastUpdated,
  getTotalPortalsCount,
} from "./ncaaPortal";
import colors from "constants/colors";

import { useLocation } from "react-router-dom";
import { InitialFilters } from "./AdvancedSearchPortal";

const loadMoreSize = 20;

function PortalResults(props: any) {
  const location = useLocation();
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [localFilters, setLocalFilters] = useState(props.filters);
  const [showHelp, setShowHelp] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc" | "">("");
  const [totalCount, setTotalCount] = useState(0);
  const [lastUpdated, setLastUpdated] = useState("");
  const [from, setFrom] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (location.search.includes("filterPortal=true")) {
      const filters = parseFiltersFromUrl(location.search);

      setLocalFilters(filters);
    }
  }, [location.search]);

  const fetchData = async (fromIndex: number) => {
    setLoading(true);
    if (props.limit && localFilters) {
      const data = await fetchFilterData(
        loadMoreSize,
        localFilters,
        fromIndex,
        sortOrder,
        sortColumn
      );

      setResults((prev) => [...prev, ...data.ncaaaRecords]);
      setTotal(data.total);
    } else {
      const data = await getAhowAllQuery(
        loadMoreSize,
        fromIndex,
        sortOrder,
        sortColumn
      );
      setResults((prev) => [...prev, ...data.ncaaaRecords]);
      setTotal(data.total);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (localFilters && props.showWithoutFilter) {
      setResults([]);
      setFrom(0);
      fetchData(0);
    }
  }, [location.search]);

  useEffect(() => {
    const initialize = async () => {
      setLoading(true);
      if (!props.showWithoutFilter) {
        const ncaa_portals_data = getAhowAllQuery(loadMoreSize, 0);
        ncaa_portals_data
          .then((data) => {
            setResults(data.ncaaaRecords);
            setTotal(data.total);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    };
    initialize();
  }, [props.showWithoutFilter]);

  // Handle sorting column toggling
  const handleSort = (key: string) => {
    if (key === "first_name") return;
    if (sortColumn === key) {
      setSortOrder((prevOrder) =>
        prevOrder === "asc" ? "desc" : prevOrder === "desc" ? "" : "asc"
      );
    } else {
      setSortColumn(key);
      setSortOrder("asc");
    }
  };

  const handleLoadMore = async () => {
    try {
      if (total === results.length) {
        return;
      }
      const newFrom = from + loadMoreSize;
      setFrom(newFrom);
      const newUrl = new URL(window.location.href);

      window.history.pushState({}, "", newUrl);

      await fetchData(newFrom);
    } catch (error) {
      console.error("Error in load more:", error);
    }
  };

  const [isNcaaPortalUrl, setIsNcaaPortalUrl] = useState(false);

  const url = window.location.href;

  useEffect(() => {
    if (url.includes("search-results?ncaaPortal=true")) {
      setIsNcaaPortalUrl(true);
    }
  }, [url]);

  useEffect(() => {
    const fetchTotalCount = async () => {
      const count = await getTotalPortalsCount();
      setTotalCount(count);
    };
    fetchTotalCount();
  }, []);

  useEffect(() => {
    const fetchLastUpdated = async () => {
      const lastUpdatedDate = await getLastUpdated();
      setLastUpdated(lastUpdatedDate);
    };
    fetchLastUpdated();
  }, []);

  useEffect(() => {
    if (sortColumn && sortOrder) {
      setResults([]);
      setFrom(0);
      fetchData(0);
    }
  }, [sortColumn, sortOrder]);

  if (!isNcaaPortalUrl) {
    return null;
  }

  return (
    <>
      <div className="search-container" style={{ minHeight: "95vh" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "85vh",
            marginLeft: "1rem",
            padding: "10px",
            flex: 1,
            overflowX: "scroll",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <ScoutTitle
              text={`RESULTS ${results?.length}`}
              withHelp={true}
              onClick={() => setShowHelp(true)}
              styles={{ color: "white" }}
              light={true}
            />
          </div>

          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "200px",
              }}
            >
              <Spinner
                animation="border"
                variant="light"
                style={{
                  width: "3rem",
                  height: "3rem",
                }}
              />
            </div>
          ) : results?.length > 0 ? (
            <ResultTableNCAA
              data={results}
              sortColumn={sortColumn}
              sortOrder={sortOrder}
              onSort={handleSort}
            />
          ) : (
            <p
              style={{
                fontStyle: "italic",
                opacity: 0.8,
                marginTop: 100,
                textAlign: "center",
                color: "white",
              }}
            >
              No results.
            </p>
          )}
          {/* Load More Button */}
          {!loading && (
            <div
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                marginTop: "20px",
              }}
            >
              <Button
                style={{
                  color: colors.accent,
                  backgroundColor: "transparent",
                  padding: 6,
                  borderWidth: 0,
                  boxShadow: "none",
                  whiteSpace: "nowrap",
                }}
                onClick={handleLoadMore}
              >
                <p
                  style={{
                    color: "white",
                    textAlign: "center",
                    opacity: 0.6,
                    textDecorationColor: colors.accent,
                    textDecoration: "underline",
                    textDecorationStyle: "solid",
                    fontSize: "22px",
                  }}
                >
                  Load More
                </p>
              </Button>
            </div>
          )}
          {localFilters && (
            <div
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                marginTop: "10px",
              }}
            >
              <Button
                style={{
                  color: colors.accent,
                  backgroundColor: "transparent",
                  padding: 6,
                  borderWidth: 0,
                  boxShadow: "none",
                  whiteSpace: "nowrap",
                }}
                onClick={() => setShowModal(true)}
              >
                <p
                  style={{
                    color: "white",
                    textAlign: "center",
                    opacity: 0.6,
                    textDecorationColor: colors.accent,
                    textDecoration: "underline",
                    textDecorationStyle: "solid",
                    fontSize: "22px",
                  }}
                >
                  Save Search
                </p>
              </Button>
            </div>
          )}
          <p
            style={{
              textAlign: "center",
              marginTop: 10,
              color: "white",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            Total Results: {total}
          </p>
          <p
            style={{
              textAlign: "center",
              marginTop: 10,
              color: "white",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            <span style={{ marginLeft: "100px" }}>
              Total: {totalCount} - Last Updated:{" "}
              {lastUpdated ? lastUpdated : "N/A"}
            </span>
          </p>
        </div>

        <SaveSearchModal
          onHide={() => setShowModal(false)}
          count={results.length}
          query={localFilters}
          show={showModal}
          ncaaPortal={true}
        />
        <HelpModal
          show={showHelp}
          onHide={() => setShowHelp(false)}
          kind={"SEARCH"}
        />
      </div>
    </>
  );
}

const parseFiltersFromUrl = (searchString: string) => {
  const params = new URLSearchParams(searchString);
  const filtersFromUrl: InitialFilters = {
    institution: "",
    graduate: false,
    mtr: false,
    transferStatus: [],
    conference: "",
    division: [],
  };

  // Convert URL parameters to filter objects
  params.forEach((value, key) => {
    if (
      [
        "conference",
        "division",
        "institution",
        "graduate",
        "mtr",
        "transferStatus",
      ].includes(key)
    ) {
      if (key === "division") {
        filtersFromUrl[key] = value.split(",").map(Number);
      } else if (key === "transferStatus") {
        filtersFromUrl[key] = value.includes(",") ? value.split(",") : [value];
      } else if (["graduate", "mtr"].includes(key)) {
        filtersFromUrl[key] = value === "true";
      } else {
        filtersFromUrl[key] = value;
      }
    }

    // Handle stat range filters
    const statKeys = [
      "yearstats",
      "era",
      "wp",
      "ra",
      "wlpct",
      "ip",
      "h",
      "r",
      "er",
      "hr",
      "bb",
      "ibb",
      "cg",
      "gs",
      "sv",
      "w",
      "l",
    ];

    statKeys.forEach((stat) => {
      if (key === `${stat}_min` || key === `${stat}_max`) {
        const isMin = key.endsWith("_min");
        if (!filtersFromUrl[stat]) {
          filtersFromUrl[stat] = {
            min: null,
            max: null,
            active: false,
          };
        }
        filtersFromUrl[stat][isMin ? "min" : "max"] = parseFloat(value);
        filtersFromUrl[stat].active = true;
      }
    });
  });

  // Clean up stat objects that weren't set
  Object.keys(filtersFromUrl).forEach((key) => {
    if (
      filtersFromUrl[key] &&
      typeof filtersFromUrl[key] === "object" &&
      !filtersFromUrl[key].active
    ) {
      delete filtersFromUrl[key];
    }
  });

  return filtersFromUrl;
};

export default PortalResults;
