import React from "react";
import colors from "constants/colors";
import { AiFillCaretUp, AiFillCaretDown } from "react-icons/ai";
import PopOver from "./Popover";

function ResultTableNCAA({ data, sortColumn, sortOrder, onSort }) {
  const headers = [
    { id: 1, name: "Player", key: "first_name" },
    { id: 3, name: "Institution", key: "institution" },
    { id: 4, name: "Graduate", key: "is_graduate" },
    { id: 5, name: "MTR", key: "multiple_transfer_portal_records" },
    { id: 6, name: "Transfer Status", key: "transfer_status" },
    { id: 7, name: "Year", key: "year" },
    { id: 8, name: "Conference", key: "conference" },
    { id: 9, name: "Division", key: "division" },
    { id: 10, name: "Email", key: "email" },
    { id: 11, name: "Initiated Date", key: "initiated_date" },
  ];

  const renderArrow = (key) => {
    if (sortColumn === key && sortOrder) {
      return sortOrder === "asc" ? <AiFillCaretUp /> : <AiFillCaretDown />;
    }
    return null;
  };

  return (
    <div style={{ width: "100%", overflowX: "auto" }}>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr
            style={{
              backgroundColor: colors.lightBlue,
              color: colors.white,
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            {headers.map((header) => (
              <th
                key={header.id}
                style={{
                  padding: "10px",
                  cursor: "pointer",
                  textAlign: "left",
                  whiteSpace: "nowrap",
                }}
                onClick={() => onSort(header.key)}
              >
                {header.name} {renderArrow(header.key)}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {data.map((player, index) => (
            <tr
              key={index}
              style={{
                backgroundColor: index % 2 === 0 ? colors.lightGray : "white",
              }}
            >
              {headers.map((header) => (
                <td
                  key={header.id}
                  style={{
                    padding: "10px",
                    textAlign: "left",
                  }}
                >
                  {header.key === "first_name" ? (
                    <PopOver
                      player={player}
                      skipPosition={true}
                      disabledMode={"none"}
                    />
                  ) : header.key === "email" ? (
                    // Make Email column clickable to open email client
                    <a
                      href={`mailto:${player[header.key]}`}
                      style={{
                        color: colors.lightBlue,
                        textDecoration: "none",
                      }}
                    >
                      {player[header.key] || "N/A"}
                    </a>
                  ) : (
                    <p>{player[header.key] || "N/A"}</p>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ResultTableNCAA;
