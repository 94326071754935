import colors from "constants/colors";
import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import { withFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import EditAffTeamDropdown from "dropdowns/edit-aff-team-dropdown";
import UnderlineButton from "shared/underline-button";
import { trackModalOpen, trackModalClose } from "../hooks/usePageTracking";

function EditProfileModal(props: {
  firestore: any;
  onHide: any;
  show: any;
  onClickDelete: any;
}) {
  const userId = useSelector((state: any) => state.firebase.auth.uid);
  const profile = useSelector((state: any) => state.firebase.profile);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [college, setCollege] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [affTeam, setAffteam] = React.useState("");

  useEffect(() => {
    if (props.show) {
      trackModalOpen("Edit Profile Modal");
    }
    return () => {
      if (props.show) {
        trackModalClose();
      }
    };
  }, [props.show]);

  useEffect(() => {
    if (profile.firstName) {
      setFirstName(profile.firstName);
    }
    if (profile.lastName) {
      setLastName(profile.lastName);
    }
    if (profile.phoneNumber) {
      setPhone(profile.phoneNumber);
    }
    if (profile.college) {
      setCollege(profile.college);
    }
    if (profile.title) {
      setTitle(profile.title);
    }
    if (profile.affiliatedTeam) {
      setAffteam(profile.affiliatedTeam);
    }
  }, []);

  const save = () => {
    let ev = {
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phone,
      college: college,
      title: title,
      affiliatedTeam: affTeam,
    };

    const docRefConfig = {
      collection: "users",
      doc: userId,
    };

    props.firestore.update(docRefConfig, ev);

    toast.dark(`Profile updated!`, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
    });

    props.onHide();
  };

  return (
    <Modal
      {...props}
      //@ts-ignore
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        style={{
          color: colors.white,
          backgroundColor: colors.accent,
          borderBottomWidth: 0,
        }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          Edit Profile
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          color: colors.white,
          backgroundColor: colors.accent,
          display: "flex",
          flexDirection: "column",
        }}
        className="searching"
      >
        <input
          type="text"
          style={{
            marginTop: 20,
            marginBottom: 10,
            display: "flex",
            flex: 1,
            border: "0px solid",
            color: "#fff",
            backgroundColor: "transparent",
            borderBottom: "2px solid #fff",
          }}
          value={firstName}
          placeholder="First Name"
          onChange={(evt) => setFirstName(evt.target.value)}
        />

        <input
          type="text"
          style={{
            marginTop: 20,
            marginBottom: 10,
            display: "flex",
            flex: 1,
            border: "0px solid",
            color: "#fff",
            backgroundColor: "transparent",
            borderBottom: "2px solid #fff",
          }}
          value={lastName}
          placeholder="Last Name"
          onChange={(evt) => setLastName(evt.target.value)}
        />

        <input
          type="text"
          style={{
            marginTop: 20,
            marginBottom: 10,
            display: "flex",
            flex: 1,
            border: "0px solid",
            color: "#fff",
            backgroundColor: "transparent",
            borderBottom: "2px solid #fff",
          }}
          value={phone}
          placeholder="Phone Number"
          onChange={(evt) => setPhone(evt.target.value)}
        />

        <input
          type="text"
          style={{
            marginTop: 20,
            marginBottom: 10,
            display: "flex",
            flex: 1,
            border: "0px solid",
            color: "#fff",
            backgroundColor: "transparent",
            borderBottom: "2px solid #fff",
          }}
          value={college}
          placeholder="College/University"
          onChange={(evt) => setCollege(evt.target.value)}
        />

        <input
          type="text"
          style={{
            marginTop: 20,
            marginBottom: 10,
            display: "flex",
            flex: 1,
            border: "0px solid",
            color: "#fff",
            backgroundColor: "transparent",
            borderBottom: "2px solid #fff",
          }}
          value={title}
          placeholder="Title"
          onChange={(evt) => setTitle(evt.target.value)}
        />

        <EditAffTeamDropdown
          value={affTeam}
          selectTeam={(val) => setAffteam(val)}
        />
      </Modal.Body>
      <Modal.Footer>
        <UnderlineButton
          text="Delete my account"
          onClick={props.onClickDelete}
        />
        <div style={{ flex: 1 }} />
        <Button
          onClick={props.onHide}
          style={{
            borderColor: colors.accent,
            backgroundColor: colors.white,
            color: colors.accent,
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            save();
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const enhance = compose(withFirestore);

export default enhance(EditProfileModal);
