/*****************************************************************************
 * Import
 *****************************************************************************/
import firebase from "firebase/compat/app";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { useMe } from "hooks/users";
import { Player } from "models/Player";


/*****************************************************************************
 * Public Hooks
 *****************************************************************************/

export const usePlayersMeta = () => {
  const me = useMe();
  const userId = me?.id || "-1";
  const key = `${userId}-playersMeta`;

  useFirestoreConnect([
    {
      collection: "users",
      doc: me?.id || "-1",
      subcollections: [
        {
          collection: "playerMeta",
        },
      ],
      storeAs: key,
    },
  ]);

  const playersMeta = useSelector<any, Partial<Player>>(
    //(state) => toArray(state.firestore.data[key] || {})
    (state) => state.firestore.data[key] || {}
  );

  return playersMeta;
};

/****************************************************************************/

export const usePlayerMeta = (playerId = "-1") => {
  const me = useMe();
  const userId = me?.id || "-1";
  const key = `${userId}-playerMeta-${playerId}`;

  useFirestoreConnect([
    {
      collection: "users",
      doc: me?.id || "-1",
      subcollections: [
        {
          collection: "playerMeta",
          doc: playerId,
        },
      ],
      storeAs: key,
    },
  ]);

  const playerMeta = useSelector<any, Partial<Player>>(
    (state) => state.firestore.data[key] ?? null
  );

  return playerMeta;
};
