export const MINS = {
  Age: 10,
  Height: 60,
  Weight: 100,
  "Graduating Class": 2022,
  Fastball: 60,
  Curveball: 55,
  "National Overall Ranking": 1,
  "National Position Ranking": 1,
  "State Overall Ranking": 1,
  "State Position Ranking": 1,
  Changeup: 55,
  Slider: 60,
  // Cutter: 60,
  Knuckleball: 60,
  "60 Time": 6.0,
  "10 Yard Split": 1.0,
  "Catcher Pop Time": 1.0,
  "Exit Velocity": 60,
  "Position Velocity": 65,
  "Position Velocity (IF)": 65,
  "Position Velocity (OF)": 65,

  "Academic Class": 1,

  "2B": 0,
  "3B": 0,
  AVG: 0,
  OBP: 0,
  SLG: 0,
  OPS: 0,
  GP: 0,
  AB: 0,
  R: 0,
  H: 0,

  HR: 0,
  RBI: 0,
  HP: 0,
  BB: 0,
  SO: 0,
  SB: 0,
  CS: 0,

  GS: 0,
  IP: 0,
  APP: 0,
  WP: 0,
  CG: 0,
  L: 0,
  SV: 0,
  OBA: 0,
  ERA: 0,
  W: 0,
  ER: 0,
  SHO: 0,

  HBP: 0,

  PA: 0,
  K: 0,

  RA: 0,
  IBB: 0,
  WLPCT: 0,
  YEARSTATS: 2000,
};

export const MAXES = {
  Age: 30,
  Height: 84,
  Weight: 400,
  "Graduating Class": 2029,
  Fastball: 105,
  Curveball: 90,
  "National Overall Ranking": 500,
  "National Position Ranking": 500,
  "State Overall Ranking": 500,
  "State Position Ranking": 500,
  Changeup: 95,
  Slider: 95,
  // Cutter: 95,
  Knuckleball: 95,
  "60 Time": 9.0,
  "10 Yard Split": 3.0,
  "Catcher Pop Time": 3.0,
  "Exit Velocity": 120,
  "Position Velocity": 105,
  "Position Velocity (IF)": 105,
  "Position Velocity (OF)": 105,

  HR: 50,
  RBI: 150,
  SLG: 1.5,
  H: 200,
  "2B": 50,
  "3B": 25,
  R: 100,
  SB: 75,
  CS: 20,
  SO: 200,
  BB: 150,
  W: 15,
  L: 15,
  ER: 100,
  GS: 25,
  APP: 50,
  IP: 200,
  SV: 25,
  CG: 20,
  SHO: 10,
  WP: 20,
  HP: 50,
  GP: 70,
  AB: 300,

  "Academic Class": 4,
  AVG: 1,
  OBP: 1,

  OPS: 2,

  OBA: 1,
  ERA: 10,

  HBP: 20,

  PA: 50,
  K: 200,
  RA: 100,
  IBB: 100,
  WLPCT: 100,
  YEARSTATS: new Date().getFullYear(),
};

export const STEPS = {
  Age: 1,
  Height: 1,
  Weight: 1,
  "Graduating Class": 1,
  Fastball: 1,
  Curveball: 1,
  "National Overall Ranking": 1,
  "National Position Ranking": 1,
  "State Overall Ranking": 1,
  "State Position Ranking": 1,

  Changeup: 1,
  Slider: 1,
  // Cutter: 1,
  Knuckleball: 1,
  "60 Time": 0.01,
  "10 Yard Split": 0.01,
  "Catcher Pop Time": 0.01,
  "Exit Velocity": 1,
  "Position Velocity": 1,
  "Position Velocity (IF)": 1,
  "Position Velocity (OF)": 1,
  "Academic Class": 1,
  AVG: 0.01,
  RBI: 1,
  BB: 1,
  OPS: 0.01,
  HR: 1,
  H: 1,
  HP: 1,
  GP: 1,
  AB: 1,
  SO: 1,
  "2B": 1,

  CS: 0.1,
  R: 1,
  SLG: 0.01,
  OBP: 0.01,
  SB: 1,
  GS: 1,
  IP: 0.01,
  APP: 1,
  WP: 1,
  CG: 0.1,
  L: 1,
  SV: 1,
  OBA: 0.01,
  ERA: 0.01,
  W: 1,
  ER: 1,
  SHO: 0.1,
  "3B": 1,

  HBP: 1,

  PA: 1,
  K: 1,
};

export const KEY_FOR_TITLE = {
  Position: "positions",
  Age: "age",
  Height: "height",
  Weight: "weight",
  "Graduating Class": "graduatingclass",
  Fastball: "maxfastball",
  Curveball: "curveball",

  "National Overall Ranking": "nationaloverallranking",
  "National Position Ranking": "nationalpositionranking",
  "State Overall Ranking": "stateoverallranking",
  "State Position Ranking": "statepositionranking",

  Changeup: "changeup",
  Slider: "slider",
  // Cutter: "cutter",
  Knuckleball: "knuckleball",
  "60 Time": "sixtytime",
  "10 Yard Split": "tenyardsplit",
  "Catcher Pop Time": "catcherpoptime",
  "Exit Velocity": "exitvelocity",
  "Position Velocity": "positionvelocityc",
  "Position Velocity (IF)": "positionvelocityif",
  "Position Velocity (OF)": "positionvelocityof",

  Commitment: "commitment",
  "Summer Team": "summerTeam",
  Conference: "conference",
  State: "states",
  Bats: "bat",
  Throws: "throw",

  "Academic Class": "classKey",
  University: "university",
  "2B": "2B",
  "3B": "3B",

  AVG: "AVG",
  RBI: "RBI",
  BB: "BB",
  OPS: "OPS",
  HR: "HR",
  H: "H",
  HP: "HP",
  GP: "GP",
  AB: "AB",
  SO: "SO",

  CS: "CS",
  R: "R",
  SLG: "SLG",
  OBP: "OBP",
  SB: "SB",
  GS: "GS",
  IP: "IP",
  APP: "APP",
  WP: "WP",
  CG: "CG",
  L: "L",
  SV: "SV",
  OBA: "OBA",
  ERA: "ERA",
  W: "W",
  ER: "ER",
  SHO: "SHO",

  HBP: "HBP",

  PA: "PA",
  K: "K",
};

export const TITLE_FOR_KEY = {
  position: "Position",
  positions: "Position",
  age: "Age",
  height: "Height",
  weight: "Weight",
  graduatingclass: "Graduating Class",
  maxfastball: "Fastball",
  curveball: "Curveball",

  nationaloverallranking: "National Overall Ranking",
  nationalpositionranking: "National Position Ranking",
  stateoverallranking: "State Overall Ranking",
  statepositionranking: "State Position Ranking",

  changeup: "Changeup",
  slider: "Slider",
  // cutter: "Cutter",
  knuckleball: "Knuckleball",
  sixtytime: "60 Time",
  tenyardsplit: "10 Yard Split",
  catcherpoptime: "Catcher Pop Time",
  exitvelocity: "Exit Velocity",
  positionvelocityc: "Position Velocity",
  positionvelocityif: "Position Velocity (IF)",
  positionvelocityof: "Position Velocity (OF)",
  commitment: "Commitment",
  uncommitted: "Commitment",
  summerTeam: "Summer Team",
  conference: "Conference",
  states: "State",
  bat: "Bats",
  throw: "Throws",
  university: "University",
  classKey: "Academic Class",
  AVG: "AVG",
  RBI: "RBI",
  BB: "BB",
  OPS: "OPS",
  HR: "HR",
  H: "H",
  HP: "HP",
  GP: "GP",
  AB: "AB",
  SO: "SO",
  "2B": "2B",

  CS: "CS",
  R: "R",
  SLG: "SLG",
  OBP: "OBP",
  SB: "SB",
  GS: "GS",
  IP: "IP",
  APP: "APP",
  WP: "WP",
  CG: "CG",
  L: "L",
  SV: "SV",
  OBA: "OBA",
  ERA: "ERA",
  W: "W",
  ER: "ER",
  SHO: "SHO",
  "3B": "3B",

  HBP: "HBP",

  PA: "PA",
  K: "K",
};

export const RANGE_TITLES = [
  "Height",
  "Weight",
  "Graduating Class",
  "Fastball",
  "Curveball",
  "National Overall Ranking",
  "National Position Ranking",
  "State Overall Ranking",
  "State Position Ranking",

  "Changeup",
  "Slider",
  // "Cutter",
  "Knuckleball",
  "60 Time",
  "10 Yard Split",
  "Catcher Pop Time",
  "Exit Velocity",
  "Position Velocity",
  "Position Velocity (IF)",
  "Position Velocity (OF)",
  "Academic Class",
  "AVG",
  "RBI",
  "BB",
  "OPS",
  "HR",
  "H",
  "HP",
  "GP",
  "AB",
  "SO",
  "2B",
  "CS",
  "R",
  "SLG",
  "OBP",
  "SB",
  "GS",
  "IP",
  "APP",
  "WP",
  "CG",
  "L",
  "SV",
  "OBA",
  "ERA",
  "W",
  "ER",
  "SHO",
  "3B",

  "HBP",

  "PA",
  "K",
];
export const PLAYER_TITLES = [
  "Graduating Class",
  "Position",

  "Height",
  "Weight",

  "National Overall Ranking",
  "National Position Ranking",
  "State Overall Ranking",
  "State Position Ranking",

  "60 Time",
  "10 Yard Split",
  "Catcher Pop Time",
  "Exit Velocity",
  "Position Velocity",
  "Position Velocity (IF)",
  "Position Velocity (OF)",

  "Fastball",
  "Curveball",
  "Changeup",
  "Slider",
  // "Cutter",
  "Knuckleball",

  "Commitment",
  "Summer Team",

  "State",
  "Bats",
  "Throws",

  "RBI",
  "BB",
  "HR",

  "HBP",
  "PA",
  "AB",
  "GS",
  "GP",
  "H",
  "K",
  "R",

  "SLG",
  "OBP",
  "OPS",

  "AVG",

  "2B",

  "3B",
];

export const COLLEGE_TITLES = [
  "Academic Class",
  "Position",
  "Conference",
  "University",

  "AVG",
  "HR",

  "RBI",
  "OPS",
  "OBP",
  "SLG",
  "H",
  "2B",
  "3B",

  "R",
  "SB",
  "CS",
  "SO",

  "BB",
  "W",
  "L",
  "ER",
  "ERA",
  "GS",
  "APP",
  "IP",
  "SV",
  "CG",
  "SHO",
  "WP",

  "HP",
  "GP",
  "AB",

  "OBA",

  "Height",
  "Weight",
];
