import React, { useEffect } from "react";

import { Provider } from "react-redux";
import { createHashHistory } from "history";
import firebase from "firebase/compat/app";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";
import createStore from "./store/store";
import ScoutRouter from "routing/ScoutRouter";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/integration/react";
import LogRocket from "logrocket";
import { useMe } from "hooks/users";

const history = createHashHistory();

const initialState = {};

const { store, persistor } = createStore(initialState, history);

const rfConfig = {
  userProfile: "users",
  useFirestoreForProfile: true,
  useFirestoreForStorageMeta: true,
};

const contextClass = {
  success: "bg-white-600",
  error: "bg-blue-600",
  info: "bg-blue-600",
  warning: "bg-blue-600",
  default: "bg-blue-600",
  dark: "bg-dark-600",
};

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ReactReduxFirebaseProvider
          firebase={firebase}
          config={rfConfig}
          dispatch={store.dispatch}
          createFirestoreInstance={createFirestoreInstance}
        >
          <AppContent />
          <ToastContainer
            //@ts-ignore
            toastClassName={({ type }) =>
              contextClass[type || "default"] +
              " flex p-2 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer"
            }
            draggable={false}
            autoClose={false}
            bodyClassName={() => "text-sm font-white font-med p-3"}
          />
        </ReactReduxFirebaseProvider>
      </PersistGate>
    </Provider>
  );
}

function AppContent() {
  const me = useMe();

  LogRocket.init("tdlhlf/scout-web-10");

  useEffect(() => {
    if (me.email) {
      LogRocket.startNewSession();
      LogRocket.identify(me.id, {
        name: `${me.firstName ?? me.data.firstName} ${
          me.lastName ?? me.data.lastName
        }`,
        email: me.email,
      });
    }
  }, [me.email]);

  return <ScoutRouter />;
}

export default App;
